import React from 'react';
import classNames from 'classnames';
import { ArrayElement } from '@magicbrief/common/src/types';

const StepIndicator: React.FC<{ isActive: boolean }> = ({
  isActive,
}): JSX.Element => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={isActive ? 'opacity-100' : 'opacity-20'}
    >
      <circle cx="12" cy="12" r="12" />
    </svg>
  );
};

const pageNumbers = [1, 2, 3] as const;
export type PageNumber = ArrayElement<typeof pageNumbers>;

const StepIndicators: React.FC<{
  currentStep: PageNumber;
  className?: string;
}> = ({ currentStep, className }): JSX.Element => {
  return (
    <div
      className={classNames(
        'flex flex-row gap-3 [&>svg]:h-3 [&>svg]:w-3 [&>svg]:fill-primary',
        className
      )}
    >
      {pageNumbers.map((step) => (
        <StepIndicator key={step} isActive={currentStep === step} />
      ))}
    </div>
  );
};

export default StepIndicators;
