import { getFeatureFlagValue } from 'src/utils/getFeatureFlagValue';
import StatusResponse = facebook.StatusResponse;

/** @see {@link https://developers.facebook.com/docs/graph-api/guides/error-handling/} */
type FBError = {
  message: string;
  type: string;
  code: number;
  error_subcode: number;
  error_user_title: string;
  error_user_msg: string;
  fbtrace_id: string;
};

/** @see {@link https://developers.facebook.com/docs/graph-api/results/} */
type FBPaging = {
  cursors: {
    before: string;
    after: string;
  };
  previous: string;
  next: string;
};

type FBResponse<T> = {
  data?: T;
  paging?: FBPaging;
  error?: FBError;
};

export const init = async () => {
  window.fbAsyncInit = function () {
    FB.init({
      appId: getFeatureFlagValue('FACEBOOK_APP_ID'),
      xfbml: true,
      version: getFeatureFlagValue('FACEBOOK_API_VERSION'),
    });
  };
};

export const login = async (): Promise<{
  fbUserID: string;
  accessToken: string;
  expiresAt: number;
}> => {
  return new Promise((resolve, reject) => {
    try {
      FB.login(
        (response: StatusResponse) => {
          switch (response.status) {
            case 'connected':
              break;
            case 'authorization_expired':
              throw new Error('Error logging in, authorization has expired');
            case 'not_authorized':
              throw new Error('Error logging in, user is not authorized');
            case 'unknown':
              throw new Error('Error logging in, unknown status received');
            default:
              throw new Error('Error logging in, user is not connected');
          }

          const {
            userID: fbUserID,
            accessToken,
            data_access_expiration_time: expiresAt,
          } = response.authResponse;

          if (accessToken == null) {
            throw new Error('No access token received');
          } else if (expiresAt == null) {
            throw new Error('No expiry for token received');
          }

          resolve({
            fbUserID,
            accessToken,
            expiresAt,
          });
        },
        {
          config_id: getFeatureFlagValue('FACEBOOK_APP_LOGIN_CONFIG_ID'),
        }
      );
    } catch (e) {
      reject(e);
    }
  });
};

type GetAdAccountsResponse = FBResponse<[{ account_id: string; name: string }]>;

export const getAdAccounts = async (
  userId = 'me'
): Promise<{ accountId: string; name: string }[]> => {
  return new Promise((resolve, reject) => {
    try {
      FB.api(
        `/${userId}/adaccounts?fields=account_id,name`,
        (response: GetAdAccountsResponse) => {
          if (response.error) {
            throw new Error(
              response.error.message ?? 'Error getting ad accounts'
            );
          }

          if (response.data == null) {
            throw new Error('No data returned');
          }

          const results = response.data.map((datum) => ({
            accountId: datum.account_id,
            name: datum.name,
          }));

          resolve(results);
        }
      );
    } catch (e) {
      reject(e);
    }
  });
};
