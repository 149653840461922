import { LibraryFilterContextValue } from 'src/pages/LibraryV2/contexts/LibraryFilterContext';

export function getLibrarySearchFiltersFromURLParams(): LibraryFilterContextValue {
  const queryString = new URLSearchParams(window.location.search);
  const brands = queryString
    .get('brands')
    ?.split(',')
    .map((x) => parseInt(x));
  const collectionQs = queryString.get('collection');
  const collection = collectionQs ? parseInt(collectionQs) : undefined;
  const format = queryString.get('format')?.split(',');
  const industries = queryString.get('industries')?.split(',');
  const savedBySelf = queryString.get('savedBySelf') === 'true';
  const search = queryString.get('search') || undefined;
  const source = queryString.get('source')?.split(',');
  const supportedPlatforms = queryString.get('supportedPlatforms')?.split(',');
  const tags = queryString
    .get('tags')
    ?.split(',')
    .map((x) => parseInt(x));
  const themes = queryString
    .get('themes')
    ?.split(',')
    .map((x) => parseInt(x));
  const scores = queryString.get('scores')?.split(',');
  const runTime = queryString.get('runTime')?.split(',');
  const languages = queryString.get('languages')?.split(',');

  return {
    brands: brands?.length ? brands : [],
    collection,
    format: format?.length
      ? (format as Required<LibraryFilterContextValue>['format'])
      : [],
    industries: industries?.length ? industries : [],
    savedBySelf,
    search,
    source: source?.length
      ? (source as Required<LibraryFilterContextValue>['source'])
      : [],
    supportedPlatforms: supportedPlatforms?.length
      ? (supportedPlatforms as Required<LibraryFilterContextValue>['supportedPlatforms'])
      : [],
    tags: tags?.length ? tags : [],
    themes: themes?.length ? themes : [],
    scores: scores?.length
      ? (scores as Required<LibraryFilterContextValue>['scores'])
      : [],
    runTime: runTime
      ? (runTime as Required<LibraryFilterContextValue>['runTime'])
      : [],
    languages: languages
      ? (languages as Required<LibraryFilterContextValue>['languages'])
      : [],
  };
}
