export const SUPPORT_EMAIL = 'hello@magicbrief.com';
export const HELP_LINK = 'https://magicbrief.academy';
export const TERMS_LINK = 'https://magicbrief.com/terms';
export const PRIVACY_LINK = 'https://magicbrief.com/privacy';
export const EXTENSION_LINK = 'https://www.magicbrief.com/extension';
export const PRICING_LINK = 'https://magicbrief.com/pricing';
export const FB_ADS_LIBRARY_LINK = 'https://www.facebook.com/ads/library';
export const FB_LINK = 'https://www.facebook.com';
export const TIKTOK_CREATIVE_LINK =
  'https://ads.tiktok.com/business/creativecenter/inspiration/topads/pc/en';
export const FEATURES_LINK = 'https://magicbrief.com/features';
export const TIKTOK_AD_LIBRARY_LINK = 'https://library.tiktok.com/ads/';
export const TIKTOK_ORGANIC_LINK = 'https://www.tiktok.com/foryou';
export const AD_SCORE_LIFECYCLE_LINK =
  'https://magicbrief.academy/ad-score-lifecycle';
export const AFFILIATE_LINK = 'https://magicbrief.com/partners';
