import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { captureException } from '@sentry/react';
import { ArrayElement } from '@magicbrief/common';
import { socket } from 'src/lib/socketio';
import { trpc } from 'src/lib/trpc';
import { useFirebaseContext } from 'src/pages/outlets/FirebaseOutlet/useFirebaseContext';

type Socket = typeof socket;

const connectionStatuses = [
  'init',
  'connecting',
  'connected',
  'disconnected',
] as const;
type ConnectionStatus = ArrayElement<typeof connectionStatuses>;

type UseSocketState = {
  status: ConnectionStatus;
};

function useSocket(): UseSocketState {
  const firebaseUser = useFirebaseContext();
  const [status, setStatus] = useState<ConnectionStatus>('init');
  const user = trpc.user.getUserInfo.useQuery(undefined, {
    enabled: !!firebaseUser,
  });
  const socketRef = useRef<Socket>();

  useEffect(() => {
    const onConnect = () => {
      setStatus('connected');
    };

    const onDisconnect = () => {
      setStatus('disconnected');
    };

    const onConnectError = (err: Error) => {
      socket.disconnect();

      captureException(err, (scope) => {
        scope.setTransactionName('Connect error');
        return scope;
      });
    };

    if (user.data != null && socketRef.current == null) {
      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.on('connect_error', onConnectError);

      socketRef.current = socket.connect();

      setStatus('connecting');
    }

    return () => {
      socket.disconnect();

      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_error', onConnectError);

      socketRef.current = undefined;
    };
  }, [user.data]);

  return {
    status,
  };
}

type SocketContextState = {
  status: ConnectionStatus;
};

export const SocketContext = createContext<SocketContextState>({
  status: 'init',
});

export const SocketProvider: FC<PropsWithChildren> = ({ children }) => {
  const { status } = useSocket();

  return (
    <SocketContext.Provider value={{ status }}>
      {children}
    </SocketContext.Provider>
  );
};
