import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { appConfigs, firebaseConfigs } from 'src/configs';
import { socket } from './socketio';

export const app = initializeApp({
  apiKey: firebaseConfigs.apiKey,
  projectId: firebaseConfigs.projectId,
  authDomain: firebaseConfigs.authDomain,
  storageBucket: firebaseConfigs.storageBucket,
});

export const auth = getAuth(app);

export const functions = getFunctions(app);

auth.onAuthStateChanged((user) => {
  if (user) {
    user
      .getIdToken()
      .then((token) => {
        socket.auth = { token };
      })
      .catch(() => {
        if (socket.connected) {
          socket.close();
        }
      });
  }
});

if (appConfigs.isFirebaseEmulator) {
  connectAuthEmulator(
    auth,
    `http://${appConfigs.firebaseEmulatorHost}:${appConfigs.firebaseEmulatorAuthPort}`
  );
  connectFunctionsEmulator(
    functions,
    appConfigs.firebaseEmulatorHost,
    appConfigs.firebaseFunctionsEmulatorPort
  );
}
