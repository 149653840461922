import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  redirect,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Redirector } from 'src/routes/Redirector';
import { RedirectWithQueryParams } from 'src/routes/RedirectWithQueryParams';
import { getFeatureFlagValue } from 'src/utils/getFeatureFlagValue';
import { init as facebookInit } from 'src/lib/facebook';
import { MagicBriefRouterErrorBoundary } from './lib/MagicBriefErrorBoundary';
import {
  AppWithNavLoader,
  AuthenticationLoader,
  BriefEditorLoader,
  BriefsLoader,
  InsightsLoader,
  LibraryLoader,
  StoryboardsLoader,
  SubRouteLoader,
} from './components/SuspenseLoaders/SuspenseLoaders';
import FirebaseOutlet from './pages/outlets/FirebaseOutlet/FirebaseOutlet';
import { productListRouteLoader } from './pages/Briefs/routes/ProductList/routeLoader';
import { productProfileRouteLoader } from './pages/Briefs/routes/ProductProfile/routeLoader';
import { brandProfileRouteLoader } from './pages/Briefs/routes/BrandProfile/routeLoader';
import LibraryDetailsOutlet from './pages/LibraryDetailsOutlet/LibraryDetailsOutlet';
import { InsightsDetailOutlet } from './pages/Insights/InsightsDetailOutlet/InsightsDetailOutlet';
import AssetDetailsOutlet from './pages/AssetDetailsOutlet/AssetDetailsOutlet';
import InitialiseUserOutlet from './pages/outlets/InitialiseUserOutlet/InitialiseUserOutlet';
import AnalyticsOutlet from './pages/outlets/AnalyticsOutlet/AnalyticsOutlet';

const SharedCollection = lazy(
  () => import('./pages/LibraryV2/routes/SharedCollection')
);
const PublicBoard = lazy(
  () => import('./pages/Board/routes/Board/components/PublicBoard')
);
const LinkedLibraryItem = lazy(() => import('./pages/LinkedLibraryItem'));
const LinkedAssetItem = lazy(() => import('./pages/LinkedAssetItem'));
const SharedBrief = lazy(() => import('./pages/Briefs/routes/SharedBrief'));
const RequireAuthenticatedOutlet = lazy(
  () => import('./pages/outlets/RequireAuthenticatedOutlet')
);
const RequireUnauthenticatedOutlet = lazy(
  () => import('./pages/outlets/RequireUnauthenticatedOutlet')
);
const MagicAi = lazy(() => import('./pages/LibraryV2/routes/MagicAi'));
const Brief = lazy(() => import('./pages/Briefs/routes/Brief'));
const Briefs = lazy(() => import('./pages/Briefs'));
const BriefList = lazy(() => import('./pages/Briefs/routes/BriefList'));
const BriefBrandProfilesProvider = lazy(
  () => import('./pages/Briefs/routes/BrandList/context')
);
const BoardsProvider = lazy(() => import('./pages/Board/context'));
const BrandList = lazy(() => import('./pages/Briefs/routes/BrandList'));
const ProductList = lazy(() => import('./pages/Briefs/routes/ProductList'));
const BrandProfile = lazy(() => import('./pages/Briefs/routes/BrandProfile'));
const ProductProfile = lazy(
  () => import('./pages/Briefs/routes/ProductProfile')
);
const Library = lazy(() => import('./pages/LibraryV2'));
const SavedLibrary = lazy(
  () => import('./pages/LibraryV2/routes/SavedLibrary')
);
const Packs = lazy(() => import('./pages/LibraryV2/routes/Packs'));
const Radar = lazy(() => import('./pages/LibraryV2/routes/Radar'));
const Brands = lazy(() => import('./pages/LibraryV2/routes/Brands'));
const Collection = lazy(() => import('./pages/LibraryV2/routes/Collection'));
const PacksCollection = lazy(
  () => import('./pages/LibraryV2/routes/Packs/PacksCollection')
);
const AssetCollection = lazy(
  () => import('./pages/Assets/routes/AssetCollection/AssetCollection')
);
const Discover = lazy(() => import('./pages/LibraryV2/routes/Discover'));
const Assets = lazy(() => import('./pages/Assets'));
const BoardList = lazy(() => import('./pages/Board/routes/BoardList'));
const AssetList = lazy(() => import('./pages/Assets/routes/AssetList'));
const Board = lazy(() => import('./pages/Board/routes/Board'));
const WorkspaceSettings = lazy(
  () => import('./pages/Settings/WorkspaceSettings')
);
const Notifications = lazy(() => import('./pages/Settings/Notifications'));
const ProfileSettings = lazy(() => import('./pages/Settings/ProfileSettings'));
const AuthenticationOutlet = lazy(
  () => import('./pages/outlets/AuthenticationOutlet')
);
const Login = lazy(
  () => import('./pages/outlets/AuthenticationOutlet/components/Login')
);
const Register = lazy(
  () => import('./pages/outlets/AuthenticationOutlet/components/Register')
);
const ResetPassword = lazy(
  () => import('./pages/outlets/AuthenticationOutlet/components/ResetPassword')
);
const Logout = lazy(
  () => import('./pages/outlets/AuthenticationOutlet/components/Logout')
);
const Authentication = lazy(() => import('./pages/Authentication'));
const Invite = lazy(() => import('./pages/Invite'));
const AuthExtension = lazy(() => import('./pages/AuthExtension'));
const Organisations = lazy(() => import('./pages/Organisations'));
const OrganisationSwitcher = lazy(
  () =>
    import(
      'src/pages/Organisations/routes/OrganisationSwitcher/OrganisationSwitcher'
    )
);
const Insights = lazy(() => import('./pages/Insights/Insights'));
const InsightsList = lazy(
  () => import('./pages/Insights/routes/InsightsList/InsightsList')
);

const routes: RouteObject[] = [
  {
    errorElement: <MagicBriefRouterErrorBoundary />,
    path: '/',
    children: [
      {
        element: <FirebaseOutlet />,
        children: [
          {
            element: <AnalyticsOutlet />,
            children: [
              // Unauthorised paths, cannot be logged in on these
              {
                element: (
                  <Suspense fallback={<AuthenticationLoader />}>
                    <RequireUnauthenticatedOutlet />
                  </Suspense>
                ),
                children: [
                  {
                    element: (
                      <Suspense fallback={<AuthenticationLoader />}>
                        <AuthenticationOutlet />
                      </Suspense>
                    ),
                    children: [
                      {
                        path: 'login',
                        element: (
                          <Suspense fallback={<AuthenticationLoader />}>
                            <Login />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'register',
                        element: (
                          <Suspense fallback={<AuthenticationLoader />}>
                            <Register />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'reset-password',
                        element: (
                          <Suspense fallback={<AuthenticationLoader />}>
                            <ResetPassword />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                ],
              },
              // Other paths
              {
                element: (
                  <InitialiseUserOutlet>
                    <LibraryDetailsOutlet>
                      <AssetDetailsOutlet>
                        <InsightsDetailOutlet>
                          <Outlet />
                        </InsightsDetailOutlet>
                      </AssetDetailsOutlet>
                    </LibraryDetailsOutlet>
                  </InitialiseUserOutlet>
                ),
                children: [
                  // Shared paths, may be either authenticated or not
                  {
                    path: 'library/share',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <Library />
                      </Suspense>
                    ),
                    children: [
                      {
                        path: 'collection/:id',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <SharedCollection />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                  {
                    path: '/briefs/concepts/:id/share/:shareLink',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <PublicBoard />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'ad/:uuid',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <LinkedLibraryItem />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'assets/:uuid',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <LinkedAssetItem />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'briefs/:shareLink/share',
                    element: (
                      <Suspense fallback={<BriefEditorLoader />}>
                        <SharedBrief />
                      </Suspense>
                    ),
                  },
                  /**
                   * @todo Remove soon! This covers a bug we had.
                   * @link https://github.com/magicbrief/undies-monorepo/pull/1145
                   */
                  {
                    path: 'brief/:shareLink',
                    element: (
                      <Suspense fallback={<BriefEditorLoader />}>
                        <Navigate
                          to={`/briefs/${window.location.pathname
                            .replace(/\/$/, '') // Remove any trailing slash
                            .split('/')
                            .pop()}/share`}
                        />
                      </Suspense>
                    ),
                  },
                  /**
                   * @todo Remove soon! This covers a bug we had.
                   * @link https://github.com/magicbrief/undies-monorepo/pull/1145
                   */
                  {
                    path: 'collection/:id',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <Navigate
                          to={`/library/share/collection/${window.location.pathname
                            .replace(/\/$/, '')
                            .split('/')
                            .pop()}`}
                        />
                      </Suspense>
                    ),
                  },
                  // Authenticated-only paths
                  {
                    path: '/',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <RequireAuthenticatedOutlet />
                      </Suspense>
                    ),
                    children: [
                      {
                        path: 'auth-extension',
                        element: (
                          <Suspense fallback={<AuthenticationLoader />}>
                            <AuthExtension />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'logout',
                        element: (
                          <Suspense fallback={<AuthenticationLoader />}>
                            <Logout />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'magicAI/:id',
                        element: (
                          <Suspense fallback={<SubRouteLoader />}>
                            <MagicAi />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'briefs/brands/:brandUUID/profile',
                        loader: brandProfileRouteLoader,
                        element: (
                          <Suspense fallback={<BriefEditorLoader />}>
                            <BriefBrandProfilesProvider>
                              <BrandProfile />
                            </BriefBrandProfilesProvider>
                          </Suspense>
                        ),
                      },
                      {
                        path: 'briefs/brands/:brandUUID/product/:productUUID/profile',
                        loader: productProfileRouteLoader,
                        element: (
                          <Suspense fallback={<BriefEditorLoader />}>
                            <BriefBrandProfilesProvider>
                              <ProductProfile />
                            </BriefBrandProfilesProvider>
                          </Suspense>
                        ),
                      },
                      {
                        path: 'briefs/*',
                        element: (
                          <BoardsProvider>
                            <Outlet />
                          </BoardsProvider>
                        ),
                        children: [
                          {
                            path: ':uuid',
                            element: (
                              <Suspense fallback={<BriefEditorLoader />}>
                                <Brief />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'concepts/:id',
                            element: (
                              <Suspense fallback={<AppWithNavLoader />}>
                                <Board />
                              </Suspense>
                            ),
                          },
                          {
                            path: '',
                            element: (
                              <Suspense fallback={<AppWithNavLoader />}>
                                <Briefs />
                              </Suspense>
                            ),
                            children: [
                              {
                                path: '',
                                element: (
                                  <Suspense fallback={<BriefsLoader />}>
                                    <BriefList />
                                  </Suspense>
                                ),
                              },
                              {
                                path: 'collection/:id',
                                element: (
                                  <Suspense fallback={<BriefsLoader />}>
                                    <BriefList />
                                  </Suspense>
                                ),
                              },
                              {
                                path: 'brands/*',
                                element: (
                                  <Suspense fallback={<BriefsLoader />}>
                                    <BriefBrandProfilesProvider>
                                      <Outlet />
                                    </BriefBrandProfilesProvider>
                                  </Suspense>
                                ),
                                children: [
                                  {
                                    path: '',
                                    element: (
                                      <Suspense fallback={<BriefsLoader />}>
                                        <BrandList />
                                      </Suspense>
                                    ),
                                  },
                                  {
                                    path: ':brandUUID',
                                    loader: productListRouteLoader,
                                    element: (
                                      <Suspense fallback={<BriefsLoader />}>
                                        <ProductList />
                                      </Suspense>
                                    ),
                                  },
                                ],
                              },
                              {
                                path: 'concepts',
                                element: (
                                  <Suspense fallback={<StoryboardsLoader />}>
                                    <BoardList />
                                  </Suspense>
                                ),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'library/*',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Library />
                          </Suspense>
                        ),
                        children: [
                          {
                            path: 'discover',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <Discover view="library" />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'discover/for-you',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <Discover view="for-you" />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'collection/:id',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <Collection />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'user/*',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <SavedLibrary usersAdsOnly />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'team/*',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <SavedLibrary usersAdsOnly={false} />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'packs/:uuid',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <PacksCollection />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'packs/*',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <Packs />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'brands',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <Brands />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'livefeed',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <Radar />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'radar',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <RedirectWithQueryParams to="/library/livefeed" />
                              </Suspense>
                            ),
                          },
                        ],
                      },
                      {
                        path: 'assets/*',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Assets />
                          </Suspense>
                        ),
                        children: [
                          {
                            path: '',
                            element: (
                              <Suspense fallback={<StoryboardsLoader />}>
                                <AssetList />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'collection/:uuid',
                            element: (
                              <Suspense fallback={<StoryboardsLoader />}>
                                <AssetCollection />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'briefs',
                            element: (
                              <Suspense fallback={<StoryboardsLoader />}>
                                <AssetList type="briefs" />
                              </Suspense>
                            ),
                          },
                        ],
                      },
                      {
                        path: 'insights/*',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Insights />
                          </Suspense>
                        ),
                        children: [
                          {
                            path: ':adAccountId',
                            element: (
                              <Suspense fallback={<InsightsLoader />}>
                                <InsightsList />
                              </Suspense>
                            ),
                          },
                        ],
                      },
                      {
                        path: 'notifications',
                        element: getFeatureFlagValue('NOTIFICATIONS_IN_APP') ? (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Notifications />
                          </Suspense>
                        ) : (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Navigate to="/" />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'organisations/*',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Organisations />
                          </Suspense>
                        ),
                        children: [
                          {
                            path: 'switch/:uuid',
                            element: (
                              <Suspense fallback={<SubRouteLoader />}>
                                <OrganisationSwitcher />
                              </Suspense>
                            ),
                          },
                        ],
                      },
                      {
                        path: 'settings',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <WorkspaceSettings />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'profile',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <ProfileSettings />
                          </Suspense>
                        ),
                      },
                      {
                        path: '/',
                        element: <Redirector />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'invite/user',
                element: (
                  <Suspense fallback={<AuthenticationLoader />}>
                    <Invite />
                  </Suspense>
                ),
              },
              {
                path: 'email-verification',
                element: (
                  <Suspense fallback={<AuthenticationLoader />}>
                    <Authentication />
                  </Suspense>
                ),
              },

              // Deprecated paths
              {
                /** @deprecated path changed, keeping for backwards compatibility, remove in 3 months */
                path: 'share_board/:id',
                loader: (args) => {
                  const id = args.params.id;
                  if (id) {
                    return redirect(`/briefs/concepts/${id}/share`);
                  }
                  return redirect('/');
                },
              },
              {
                /** @deprecated path changed, keeping for backwards compatibility, remove in 3 months */
                path: 'projects/:id',
                loader: (args) => {
                  const id = args.params.id;
                  if (id) {
                    return redirect(`/briefs/concepts/${id}`);
                  }
                  return redirect('/');
                },
              },
              {
                /** @deprecated path changed, keeping for backwards compatibility, remove in 3 months */
                path: 'projects/share/:id',
                loader: (args) => {
                  const id = args.params.id;
                  if (id) {
                    return redirect(`/briefs/concepts/${id}/share`);
                  }
                  return redirect('/');
                },
              },
              {
                /** @deprecated path changed, keeping for backwards compatibility, remove in 3 months */
                path: 'projects',
                loader: () => {
                  return redirect('/briefs/concepts');
                },
              },
              {
                /** @deprecated path changed, keeping for backwards compatibility, remove in 3 months */
                path: 'library',
                loader: () => {
                  return redirect('/library/discover');
                },
              },
              {
                /** @deprecated path changed, keeping for backwards compatibility, remove in 3 months */
                path: 'boards',
                loader: () => {
                  return redirect('/briefs/concepts');
                },
              },
              {
                /** @deprecated path changed, keeping for backwards compatibility, remove in 3 months */
                path: `/boards/:id/share`,
                loader: (args) => {
                  const id = args.params.id;
                  if (id) {
                    return redirect(`/briefs/concepts/${id}/share`);
                  }
                  return redirect('/');
                },
              },
              {
                /** @deprecated path changed, keeping for backwards compatibility, remove in 3 months */
                path: `/boards/:id/share/:shareLink`,
                loader: (args) => {
                  const { id, shareLink } = args.params;
                  if (id && shareLink) {
                    return redirect(
                      `/briefs/concepts/${id}/share/${shareLink}`
                    );
                  }
                  return redirect('/');
                },
              },
              {
                /** @deprecated path changed, keeping for backwards compatibility, remove in 3 months */
                path: 'boards/:id',
                loader: (args) => {
                  const id = args.params.id;
                  if (id) {
                    return redirect(`/briefs/concepts/${id}`);
                  }
                  return redirect('/');
                },
              },
              {
                /** @deprecated path changed, keeping for backwards compatibility, remove in 3 months */
                path: 'boards/:id/share',
                loader: (args) => {
                  const id = args.params.id;
                  if (id) {
                    return redirect(`/briefs/concepts/${id}/share`);
                  }
                  return redirect('/');
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router: ReturnType<typeof createBrowserRouter> =
  sentryCreateBrowserRouter(routes);

function App() {
  void facebookInit();
  return <RouterProvider router={router} />;
}

export default App;
