import { MBOrganisation } from '@magicbrief/server/src/process/organisation';
import Spinner from 'src/components/Loaders/Spinner';
import { BaseModal, ModalHeader } from 'src/components/Modal';
import OrganisationAvatar from 'src/components/OrganisationAvatar';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';

type Props = {
  show: boolean;
  onClose: () => void;
  currentOrg?: MBOrganisation;
};

const SwitchWorkspaceModal: React.FunctionComponent<Props> = ({
  show,
  onClose,
  currentOrg,
}): JSX.Element => {
  const { LL } = useI18nContext();
  const switchOrg = trpc.user.updateUserCurrentOrganisation.useMutation();
  const allOrgs = trpc.user.getUserOrgs.useQuery();
  const filteredOrgs =
    allOrgs.data?.filter((x) => x.identifier !== currentOrg?.orgIdentifier) ??
    [];
  return (
    <BaseModal
      show={show}
      onClose={onClose}
      panelClassName="flex flex-col max-w-sm w-full overflow-clip max-h-[70vh] bg-white rounded-xl"
    >
      <ModalHeader onClose={onClose} className="px-8 pt-6 ">
        {LL.workspaceSwitcher.title()}
      </ModalHeader>

      <div className="mt-2 flex flex-row items-center justify-between gap-4 border-b border-solid border-b-purple-300 px-8 py-6">
        {switchOrg.isLoading ? (
          <div className="flex flex-auto flex-col items-center gap-4 self-center text-primary">
            <Spinner />
            <p className="shrink text-center font-semibold text-primary">
              {LL.workspaceSwitcher.switching({
                name:
                  filteredOrgs.find(
                    (x) => x.orgID === switchOrg.variables?.company_id
                  )?.orgName ?? '',
              })}
            </p>
          </div>
        ) : (
          <div className="flex shrink flex-row items-center gap-4 overflow-hidden">
            <div className="flex flex-row items-center gap-4 overflow-hidden">
              <div className="h-8 w-8 shrink-0">
                <OrganisationAvatar profilePicURL={currentOrg?.profilePicURL} />
              </div>
              <span className="shrink truncate font-semibold text-primary">
                {currentOrg?.name}
              </span>
            </div>
            <div className="flex h-6 items-center justify-center rounded-full border border-solid border-purple-500 px-2 text-xs text-purple-500">
              {LL.workspaceSwitcher.current()}
            </div>
          </div>
        )}
      </div>

      <div className="align-center flex flex-auto shrink flex-col divide-y divide-solid divide-purple-100 overflow-auto p-4">
        {allOrgs.isLoading && (
          <div className="text-primary">
            <Spinner />
          </div>
        )}

        {allOrgs.data &&
          filteredOrgs.map((org) => (
            <button
              disabled={switchOrg.isLoading}
              onClick={() => {
                void switchOrg
                  .mutateAsync({
                    company_id: org.orgID,
                    organisationId: org.orgID,
                  })
                  .then(() => {
                    window.location.reload();
                  });
              }}
              key={org.orgID}
              className="flex shrink-0 cursor-pointer appearance-none flex-row items-center gap-4 overflow-hidden bg-white px-4 py-6 first:rounded-t-lg last:rounded-b-lg hover:bg-purple-100 disabled:cursor-not-allowed disabled:opacity-70"
            >
              <div className="h-8 w-8 shrink-0">
                <OrganisationAvatar profilePicURL={org.profilePicURL} />
              </div>
              <span className="shrink truncate font-semibold text-primary">
                {org.orgName}
              </span>
            </button>
          ))}
        {allOrgs.data && filteredOrgs.length === 0 && (
          <span className="text-center text-sm font-normal text-gray-500">
            {LL.workspaceSwitcher.none()}
          </span>
        )}
      </div>
    </BaseModal>
  );
};

export default SwitchWorkspaceModal;
