import { useRef } from 'react';
import { Icon } from 'src/components/Icon';
import Translate from 'src/components/HOCs/Translate';
import { BaseModal } from 'src/components/Modal';
import { useI18nContext } from 'src/i18n/i18n-react';
import { EXTENSION_LINK, HELP_LINK } from 'src/static/links';
import XClose from 'src/assets/svgicons/duocolor/x-close.svg';
import GoogleChrome from 'src/assets/svgicons/duotone/google-chrome.svg';
import BookOpen01 from 'src/assets/svgicons/duotone/book-open-01.svg';
import useAnalyticsEvent from 'src/utils/useAnalyticsEvent';
import './Wistia.css';
import { trpc } from 'src/lib/trpc';
import { segment } from 'src/lib/segment';

type Props = {
  show: boolean;
  onClose: () => void;
};

const VideoModal: React.FunctionComponent<Props> = ({
  show,
  onClose,
}): JSX.Element => {
  const { LL } = useI18nContext();
  const userEvent = useAnalyticsEvent();
  const updateUserInfo = trpc.user.updateUserInfo.useMutation();
  const ref = useRef<HTMLDivElement>(null);

  const closeVideoModal = async () => {
    await updateUserInfo.mutateAsync({
      metadata: {
        videoModalFinished: true,
      },
    });
    void userEvent({
      eventName: 'video_modal:dismissed',
    });
    onClose();
  };

  return (
    <BaseModal
      show={show}
      onClose={closeVideoModal}
      panelClassName="flex flex-col max-w-2xl bg-white rounded-xl p-7"
    >
      <div className="flex flex-col gap-5">
        <div className="flex justify-between items-center">
          <h3 className="text-[24px] tracking-tight font-semibold leading-8 text-primary cursor-default">
            <Translate
              text={LL.learnMagic()}
              renderComponent={(additional) => (
                <span key="additional" className="text-primary/60 font-normal">
                  {additional}
                </span>
              )}
            />
          </h3>
          <button
            className="h-6 w-6 appearance-none text-text-secondary"
            onClick={closeVideoModal}
          >
            <Icon>
              <XClose />
            </Icon>
          </button>
        </div>
        <div className="text-clip rounded">
          <div
            className="wistia_responsive_padding"
            style={{
              padding: '56.25% 0 0 0',
              position: 'relative',
            }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
              }}
            >
              <div
                className="wistia_embed wistia_async_82u681qrkr seo=true videoFoam=true"
                style={{
                  height: '100%',
                  position: 'relative',
                  width: '100%',
                }}
              >
                <div
                  className="wistia_swatch"
                  style={{
                    height: '100%',
                    left: 0,
                    opacity: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    top: 0,
                    transition: 'opacity 200ms',
                    width: '100%',
                  }}
                  ref={ref}
                >
                  <img
                    src="/images/video_modal_swatch.jpeg"
                    style={{
                      height: '100%',
                      filter: 'blue(5px)',
                      objectFit: 'contain',
                      width: '100%',
                    }}
                    alt=""
                    aria-hidden="true"
                    onLoad={() =>
                      ref.current && (ref.current.style.opacity = '1')
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 justify-between text-primary">
          <a
            target="_blank"
            rel="noreferrer"
            href={EXTENSION_LINK}
            onClick={() => {
              void segment?.track('viewed_chromestore');
              void userEvent({
                eventName: 'video_modal:extension_link_clicked',
              });
            }}
            className="w-full items-center flex gap-3 bg-white rounded-lg py-3 px-4 border border-solid border-secondary shadow"
          >
            <Icon>
              <GoogleChrome />
            </Icon>
            <div>
              <div className="text-sm whitespace-nowrap">
                {LL.downloadExtension()}
              </div>
              <div className="text-xs text-primary/50 font-normal">
                {LL.saveAds()}
              </div>
            </div>
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href={HELP_LINK}
            onClick={() => {
              void userEvent({
                eventName: 'video_modal:help_link_clicked',
              });
            }}
            className="w-full items-center flex gap-3 bg-white rounded-lg py-3 px-4 border border-solid border-secondary shadow"
          >
            <Icon>
              <BookOpen01 />
            </Icon>
            <div>
              <div className="text-sm whitespace-nowrap">
                {LL.gettingStarted()}
              </div>
              <div className="text-xs text-primary/50 font-normal">
                {LL.becomeWizard()}
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="absolute bg-purple-100 w-full left-0 h-1/2 -z-10 rounded-b-lg bottom-0" />
    </BaseModal>
  );
};

export default VideoModal;
