import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MBOrganisation } from '@magicbrief/server/src/process/organisation';
import * as Sentry from '@sentry/react';
import * as z from 'zod';
import { captureException } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { UserOrg } from '@magicbrief/server/src/process/user';
import { getQueryKey } from '@trpc/react-query';
import {
  GetUserInfoResponse,
  UserOnboardInput,
} from '@magicbrief/server/src/trpc/@types/user';
import { zodResolver } from '@hookform/resolvers/zod';
import { BaseModal } from 'src/components/Modal';
import WelcomeToMagicBrief from 'src/assets/WelcomeToMagicBrief.svg';
import { Select } from 'src/components/Select';
import Input from 'src/components/Input';
import { Button, ButtonLink } from 'src/components/Button';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import {
  companyRoles,
  companyTypes,
  CompanyTypeV3,
  userIndustries,
} from 'src/pages/Settings/const';
import InviteTeamOnboarding from 'src/components/InviteTeamOnboarding';
import { segment } from 'src/lib/segment';
import Testimonial from '../UpgradeModal/components/Testimonial';
import Plan from './Plan';
import StepIndicators, { PageNumber } from './StepIndicators';

/**
 * @todo clean this up and get imports working between server and frontend packages
 */
const userOnboardInput = z
  .object({
    companyName: z
      .string({ required_error: 'Company name is required' })
      .min(3, { message: 'Company name must be at least 3 characters long' })
      .max(100, {
        message: 'Company name must be less than 100 characters long',
      }),
    companyWebsite: z
      .string({ required_error: 'Company website is required' })
      .min(4, { message: 'Company website must be at least 4 characters long' })
      .max(50, {
        message: 'Company website must be less than 50 characters long',
      }),
    companyType: z.string({
      required_error: 'Company type is required',
    }),
    userRole: z.string().optional(),
    companyIndustry: z.string().optional(),
  })
  .refine(
    (schema) => !(schema.companyType === 'agency' && schema.userRole == null),
    {
      path: ['userRole'],
      message: 'User role is required',
    }
  )
  .refine(
    (schema) =>
      !(schema.companyType === 'brand' && schema.companyIndustry == null),
    {
      path: ['companyIndustry'],
      message: 'Industry is required',
    }
  );

type Props = {
  organisation: MBOrganisation;
  onComplete: () => void;
};

const OnboardingModal: FC<Props> = ({ organisation, onComplete }) => {
  const [show, setShow] = useState(true);
  const userInfo = trpc.user.getUserInfo.useQuery();
  const onboard = trpc.user.completeUserOnboardForm.useMutation();
  const { LL } = useI18nContext();
  const [currentStep, setCurrentStep] = useState<PageNumber>(1);
  const queryClient = useQueryClient();

  const defaultValues = {
    companyName:
      organisation.name !== 'My Organisation' ? organisation.name : undefined,
    companyWebsite: organisation.companyWebsite ?? undefined,
    companyType: organisation.companyType ?? undefined,
    userRole: userInfo.data?.userRole ?? undefined,
    companyIndustry: organisation.industry ?? undefined,
  };

  const {
    register,
    unregister,
    watch,
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
  } = useForm<UserOnboardInput>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: zodResolver(userOnboardInput),
  });

  const onSubmit = handleSubmit(async (data: UserOnboardInput) => {
    const mutationData = {
      companyName: data.companyName ?? undefined,
      companyType: data.companyType ?? undefined,
      companyWebsite: data.companyWebsite ?? undefined,
      userRole: data.userRole ?? undefined,
      companyIndustry: data.companyIndustry ?? undefined,
    };

    onboard.mutate(mutationData, {
      onSuccess: (data) => {
        setCurrentStep(2);

        queryClient.setQueryData<GetUserInfoResponse>(
          getQueryKey(trpc.user.getUserInfo, undefined, 'query'),
          () => data.user
        );

        queryClient.setQueryData<MBOrganisation>(
          getQueryKey(trpc.organisation.getOrganisation, undefined, 'query'),
          () => data.organisation
        );

        queryClient.setQueryData<UserOrg[]>(
          getQueryKey(trpc.user.getUserOrgs, undefined, 'query'),
          (prev) => {
            if (prev) {
              const idx = prev.findIndex(
                (x) => x.identifier === data.organisation.orgIdentifier
              );
              if (idx !== -1) {
                const existing = prev[idx];
                return [
                  ...prev.slice(0, idx),
                  { ...existing, orgName: organisation.name },
                  ...prev.slice(idx + 1),
                ];
              }
            }
            return prev;
          }
        );

        void segment?.track('completed_signup');
      },
      onError: (err) => {
        captureException(err, (scope) => {
          scope.setLevel('error');
          scope.setTransactionName('User onboarding');
          scope.setUser({
            firebaseID: userInfo.data?.firebaseID,
            email: userInfo.data?.email,
          });
          scope.setExtras(mutationData);
          return scope;
        });
      },
    });
  });

  const companyTypeWatcher = watch('companyType');

  useEffect(() => {
    switch (companyTypeWatcher) {
      case CompanyTypeV3.Agency:
        register('userRole');
        unregister('companyIndustry');
        break;
      case CompanyTypeV3.Brand:
        register('companyIndustry');
        unregister('userRole');
        break;
      default:
        unregister('companyIndustry');
        unregister('userRole');
    }
  }, [companyTypeWatcher, register, unregister]);

  const currentCompanyType = getValues('companyType');

  const subscription = trpc.stripe.getSubscription.useQuery(
    { location: location.pathname },
    {
      staleTime: 1000 * 60 * 15, // 15 minute stale time, then re-query
      onError(e) {
        Sentry.captureException(e);
      },
    }
  );

  const hasEditPermission =
    userInfo.data?.role != null &&
    ['wizard', 'superuser', 'owner', 'admin'].includes(userInfo.data.role);

  return (
    <BaseModal
      show={show}
      onClose={null}
      afterClose={onComplete}
      panelClassName="bg-white rounded-xl"
    >
      <div className="box-content max-w-lg lg:max-w-none flex flex-col lg:grid lg:min-h-[478px] lg:w-[764px] lg:grid-cols-2">
        <div className="relative col-span-1 flex flex-col justify-between rounded-t-xl bg-purple-100 px-8 pb-6 pt-8 text-primary lg:rounded-l-xl lg:rounded-tr-none lg:p-10">
          <div className="flex flex-col items-start justify-between pb-14 h-full">
            <div>
              <h3 className="text-[24px] font-extrabold text-primary">
                {currentStep === 1 && (
                  <WelcomeToMagicBrief className="h-18 text-primary fill-primary mt-2" />
                )}
                {currentStep === 2 && (
                  <span>{LL.onboarding.inviteTitle()}</span>
                )}
                {currentStep === 3 && <span>{LL.onboarding.planTitle()}</span>}
              </h3>
              <p className="mt-4 max-w-md text-sm text-primary/90 lg:pr-8">
                {currentStep === 1 && (
                  <span>{LL.onboarding.description()}</span>
                )}
                {currentStep === 2 && (
                  <span>{LL.onboarding.inviteDescription()}</span>
                )}
                {currentStep === 3 && (
                  <span>{LL.onboarding.planDescription()}</span>
                )}
              </p>
            </div>
            {currentStep === 2 && (
              <img
                className="-ml-0.5 hidden lg:block scale-125"
                src="/images/invite.png"
                alt=""
              />
            )}
            {currentStep === 3 && (
              <Testimonial className="text-sm hidden lg:flex" />
            )}
          </div>
          <StepIndicators
            currentStep={currentStep}
            className="absolute right-8 lg:left-10 lg:bottom-10"
          />
        </div>

        {currentStep === 1 && (
          <form onSubmit={onSubmit} className="col-span-1 flex flex-col">
            <div className="flex flex-auto flex-col gap-4 px-8 pb-8 pt-6 lg:py-10">
              <Input
                {...register('companyName', {
                  required: {
                    value: true,
                    message: LL.errors.fieldRequired(),
                  },
                })}
                autoComplete="organization"
                placeholder={LL.placeholders.companyName()}
                error={errors.companyName?.message}
                disabled={!hasEditPermission}
                label={LL.labels.companyName()}
              />

              <Input
                {...register('companyWebsite', {
                  required: {
                    value: true,
                    message: LL.errors.fieldRequired(),
                  },
                })}
                autoComplete="url"
                placeholder={LL.placeholders.companyWebsite()}
                error={errors.companyWebsite?.message}
                disabled={!hasEditPermission}
                label={LL.labels.companyWebsite()}
              />

              <Controller
                name="companyType"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: LL.errors.fieldRequired(),
                  },
                }}
                render={({ field, formState }) => (
                  <Select
                    error={errors.companyType?.message}
                    disabled={!hasEditPermission}
                    renderOptionLabel={(opt: string) => {
                      const tFunc =
                        LL.companyTypes[opt as keyof typeof LL.companyTypes];
                      return tFunc ? tFunc() : opt;
                    }}
                    defaultValue={
                      field.value ?? formState.defaultValues?.companyType ?? ''
                    }
                    label={LL.labels.companyType()}
                    options={companyTypes}
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />

              {currentCompanyType === CompanyTypeV3.Agency.toString() && (
                <Controller
                  name="userRole"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: LL.errors.fieldRequired(),
                    },
                  }}
                  render={({ field, formState }) => (
                    <Select
                      error={errors.userRole?.message}
                      renderOptionLabel={(opt) => {
                        const tFunc =
                          LL.onboarding.companyRoles[
                            opt as keyof typeof LL.onboarding.companyRoles
                          ];
                        return tFunc ? tFunc() : opt;
                      }}
                      defaultValue={
                        field.value ?? formState.defaultValues?.userRole ?? ''
                      }
                      label={LL.labels.role()}
                      options={companyRoles}
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
              )}

              {currentCompanyType === CompanyTypeV3.Brand.toString() && (
                <Controller
                  name="companyIndustry"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: LL.errors.fieldRequired(),
                    },
                  }}
                  render={({ field, formState }) => (
                    <Select
                      disabled={onboard.isLoading}
                      defaultValue={
                        field.value ??
                        formState.defaultValues?.companyIndustry ??
                        ''
                      }
                      renderOptionLabel={(opt) => {
                        const tFunc =
                          LL.industries[opt as keyof typeof LL.industries];
                        return tFunc ? tFunc() : opt;
                      }}
                      label={LL.labels.industry()}
                      options={userIndustries}
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
              )}

              <Button
                htmlType="submit"
                className="mt-6 lg:mt-auto"
                disabled={!isValid}
              >
                {LL.onboarding.continue()}
              </Button>
            </div>
          </form>
        )}

        {currentStep === 2 && (
          <div className="col-span-1 flex flex-col">
            <InviteTeamOnboarding
              isOnboarding={true}
              onComplete={() => setCurrentStep(3)}
              className="px-5 pb-8 pt-6 lg:py-10"
            >
              {({ hasEmails, isSubmitting }) => (
                <div className="flex flex-row justify-end pt-6 lg:pt-0">
                  <div className="flex flex-row gap-3">
                    <Button variant="text" onClick={() => setCurrentStep(3)}>
                      {LL.onboarding.skip()}
                    </Button>
                    <Button
                      disabled={!hasEmails}
                      htmlType="submit"
                      loading={isSubmitting}
                    >
                      {LL.onboarding.invite()}
                    </Button>
                  </div>
                </div>
              )}
            </InviteTeamOnboarding>
          </div>
        )}

        {currentStep === 3 && (
          <div className="col-span-1 flex flex-row p-5 gap-5 justify-between lg:flex-col">
            <Plan
              plan={LL.workspaceSettings.starter.title()}
              price={LL.workspaceSettings.starter.description()}
              features={[LL.workspaceSettings.starter.feature1()]}
            >
              <Button
                flat
                variant="white"
                className="w-full mb-5 lg:mb-0"
                onClick={() => setShow(false)}
              >
                {LL.workspaceSettings.starter.startForFree()}
              </Button>
            </Plan>
            <Plan
              plan={LL.workspaceSettings.professional.title()}
              price={LL.workspaceSettings.professional.description()}
              extrasPrice={LL.workspaceSettings.professional.extra()}
              features={[
                LL.workspaceSettings.professional.feature1Unstyled(),
                LL.workspaceSettings.professional.feature2(),
                LL.workspaceSettings.professional.feature3(),
                LL.workspaceSettings.professional.feature4(),
                LL.workspaceSettings.professional.feature5(),
              ]}
              background="bg-secondary"
            >
              <div className="flex flex-col gap-2">
                <ButtonLink
                  type="external"
                  href={subscription?.data?.redirectURL || ''}
                  variant="gradient"
                  target="_self"
                  flat
                  className="w-full"
                >
                  {LL.workspaceSettings.professional.selectPro()}
                </ButtonLink>
                <div className="text-xxs text-center text-primary/50 -mb-1">
                  {LL.upgrade.refundCancelAnytime()}
                </div>
              </div>
            </Plan>
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default OnboardingModal;
