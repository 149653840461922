import { FC, useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import UpgradeModal from 'src/components/UpgradeModal/UpgradeModal';
import { trpc } from 'src/lib/trpc';
import VideoModal from 'src/components/VideoModal';
import OnboardingModal from 'src/components/OnboardingModal';
import ManageInviteModal from '../ManageInviteModal';

const UniversalModals: FC = () => {
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [paywallDismissed, setPaywallDismissed] = useState(false);
  const organisation = trpc.organisation.getOrganisation.useQuery();
  const user = trpc.user.getUserInfo.useQuery();

  const isSettings = !!useMatch({ path: '/settings', end: true });

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    const listener = (ev: MediaQueryListEvent) => {
      setIsDesktop(ev.matches);
    };

    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', listener);
    } else if (mediaQuery.addListener) {
      mediaQuery.addListener(listener);
    }

    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', listener);
      } else if (mediaQuery.removeListener) {
        mediaQuery.removeListener(listener);
      }
    };
  }, []);

  useEffect(() => {
    const isUserEligibleToOnboard =
      user.data != null &&
      !user.data.onboarded &&
      user.data.role === 'owner' &&
      !isOnboarding;

    if (isUserEligibleToOnboard) {
      setIsOnboarding(true);
    }
  }, [user.data, isOnboarding]);

  return (
    <>
      {organisation.data && isDesktop && isOnboarding && (
        <OnboardingModal
          onComplete={() => {
            setIsOnboarding(false);
            setShowVideoModal(true);
          }}
          organisation={organisation.data}
        />
      )}
      <ManageInviteModal disabled={isOnboarding} />
      {isDesktop && (
        <VideoModal
          show={showVideoModal}
          onClose={() => setShowVideoModal(false)}
        />
      )}
      <UpgradeModal
        show={
          !paywallDismissed &&
          !isSettings &&
          organisation.data?.billingState === 'paywalled'
        }
        hardPaywall={false}
        onClose={() => setPaywallDismissed(true)}
      />
    </>
  );
};

export default UniversalModals;
