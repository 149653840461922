import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from 'src/components/Icon';
import ArrowLeft from 'src/assets/svgicons/line/arrow-left.svg';
import MagicBriefBolt from 'src/assets/svgicons/magicbrief/MagicBriefBolt.svg';
import Navigation from 'src/components/Navigation';
import BaseLibraryToolbar from 'src/pages/LibraryV2/components/LibraryToolbar/components/BaseLibraryToolbar/BaseLibraryToolbar';
import { trpc } from 'src/lib/trpc';
import { useFirebaseContext } from 'src/pages/outlets/FirebaseOutlet/useFirebaseContext';
import LibraryGrid from '../LibraryGrid/LibraryGrid';
import Avatar from '../Misc/Avatar';
import { SquareLoaders } from '../SquareLoaders';

export const AppWithNavLoader: React.FunctionComponent = (): JSX.Element => {
  const user = useFirebaseContext();
  const userInfo = trpc.user.getUserInfo.useQuery(undefined, {
    enabled: !!user?.emailVerified,
  });
  return (
    <div className="relative flex flex-col h-full">
      {userInfo.data ? (
        <Navigation />
      ) : (
        <Navigation.Base mobileMenu={null}>
          <div className="flex flex-row items-center">
            <Navigation.DefaultLead />
            <div className="hidden flex-row gap-2 items-center px-6 lg:flex">
              <Navigation.SkeletonLinks />
            </div>
          </div>

          <Navigation.Section>
            <Avatar
              initial={user?.displayName?.charAt(0)}
              className="h-9 w-9"
            />
          </Navigation.Section>
        </Navigation.Base>
      )}
      <div className="flex w-full grow items-center justify-center">
        <Icon className="text-purple-800 animate-pulse">
          <MagicBriefBolt />
        </Icon>
      </div>
    </div>
  );
};

export const SubRouteLoader: React.FunctionComponent = (): JSX.Element => {
  return (
    <div className="flex w-full grow items-center justify-center">
      <Icon className="animate-pulse text-purple-800">
        <MagicBriefBolt />
      </Icon>
    </div>
  );
};

export const AuthenticationOutletLoader: React.FunctionComponent =
  (): JSX.Element => {
    return (
      <div className="w-full rounded-xl shadow-md bg-white flex items-center justify-center p-6 h-64">
        <Icon className="animate-pulse text-purple-800">
          <MagicBriefBolt />
        </Icon>
      </div>
    );
  };

export const AuthenticationLoader: React.FunctionComponent =
  (): JSX.Element => {
    return (
      <div className="h-full w-full bg-mobile-register bg-cover bg-center bg-no-repeat sm:bg-desktop-register">
        <div className="w-64 z-10 m-auto box-content flex h-[calc(100vh_-_24px)] flex-col items-center justify-center p-3">
          <AuthenticationOutletLoader />
        </div>
      </div>
    );
  };

export const LibraryLoader: React.FunctionComponent<{
  showToolbar?: boolean;
}> = ({ showToolbar = true }): JSX.Element => {
  return (
    <div className="relative flex min-w-0 grow flex-col">
      {showToolbar && (
        <BaseLibraryToolbar
          actions={null}
          mobileMenu={null}
          className="top-[72px]"
          title={
            <div className="bg-primary/10 rounded-lg animate-pulse w-32 h-6" />
          }
        />
      )}
      <LibraryGrid isLoading isFetching={false} />
    </div>
  );
};

export const StoryboardsLoader: React.FunctionComponent<{
  showToolbar?: boolean;
}> = ({ showToolbar = true }): JSX.Element => {
  return (
    <div className="relative flex min-w-0 grow flex-col">
      {showToolbar && (
        <BaseLibraryToolbar
          actions={null}
          mobileMenu={null}
          className="top-[72px]"
          title={
            <div className="bg-primary/10 rounded-lg animate-pulse w-32 h-6" />
          }
        />
      )}
      <div className="flex flex-col gap-4 p-5 sm:grid sm:grid-cols-[repeat(auto-fill,minmax(32rem,auto))] lg:p-8">
        <SquareLoaders amount={20} className="h-36" />
      </div>
    </div>
  );
};

export const InsightsLoader: FC = () => {
  return (
    <div className="relative flex min-w-0 grow flex-col">
      <LibraryGrid isLoading isFetching={false} />
    </div>
  );
};

export const BriefsLoader: React.FunctionComponent<{
  showToolbar?: boolean;
}> = ({ showToolbar = true }) => {
  return (
    <div className="relative flex min-w-0 grow flex-col">
      {showToolbar && (
        <BaseLibraryToolbar
          actions={null}
          mobileMenu={null}
          className="top-[72px]"
          title={
            <div className="bg-primary/10 rounded-lg animate-pulse w-32 h-6" />
          }
        />
      )}
      <div className="flex flex-col gap-4 p-5 sm:grid sm:grid-cols-[repeat(auto-fill,minmax(24rem,auto))] lg:p-8">
        <SquareLoaders amount={20} className="h-36" />
      </div>
    </div>
  );
};

export const BriefEditorLoader: React.FunctionComponent = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="relative flex flex-col h-full">
      <Navigation.Base mobileMenu={null}>
        <Navigation.Section>
          <Navigation.Button
            onClick={() => {
              if (location.key === 'default') {
                navigate('/briefs', { replace: true });
              } else {
                navigate(-1);
              }
            }}
            icon={
              <Icon>
                <ArrowLeft />
              </Icon>
            }
          />
        </Navigation.Section>
        <div className="w-32 text-white rounded-lg bg-white/10 animate-pulse" />
        <Navigation.Section />
      </Navigation.Base>
      <div className="flex w-full flex-auto">
        <div className="flex w-full flex-auto flex-col bg-white px-4 py-8 sm:px-10">
          <div className="flex flex-col gap-4">
            <SquareLoaders amount={20} className="h-5.5" />
          </div>
        </div>
      </div>
    </div>
  );
};
