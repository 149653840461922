import classNames from 'classnames';
import { useI18nContext } from 'src/i18n/i18n-react';
import customerProfile from './jed_ellington_profile.jpg';

const Testimonial: React.FC<{ className?: string }> = ({
  className,
}): JSX.Element => {
  const { LL } = useI18nContext();

  return (
    <div
      className={classNames(
        'flex gap-5 flex-col leading-normal tracking-normal rounded-2xl text-primary/90',
        className
      )}
    >
      <div>{LL.testimonial.jedTestimonial()}</div>
      <div className="flex items-center gap-3">
        <div className="w-12 h-12 rounded-full overflow-hidden">
          <img
            src={customerProfile}
            className="scale-105 object-cover"
            alt="Testimonial"
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="font-semibold text-sm">{LL.testimonial.jed()}</div>
          <div className="text-primary/60 text-xs">
            {LL.testimonial.cubbyCreative()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
