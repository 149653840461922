import { AnalyticsBrowser } from '@segment/analytics-next';

export let segment: AnalyticsBrowser | null = null;

export function initSegment() {
  if (import.meta.env.VITE_SEGMENT_FRONTEND_KEY) {
    const key = import.meta.env.VITE_SEGMENT_FRONTEND_KEY;
    segment = AnalyticsBrowser.load({ writeKey: key });
  }
}
