import { forwardRef } from 'react';
import classNames from 'classnames';
import { Icon } from 'src/components/Icon';
import AlertOctagon from 'src/assets/svgicons/duocolor/alert-octagon.svg';
import { baseStyle, validStyle, invalidStyle } from './styles';

type Props = React.PropsWithChildren<{
  label: string;
  name: string;
  error?: string | null;
  suffixIcon?: React.ReactNode;
}> &
  Omit<React.ComponentPropsWithoutRef<'input'>, 'name'>;

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      placeholder,
      children,
      name,
      value,
      readOnly,
      error,
      className,
      suffixIcon,
      ...rest
    },
    ref
  ) => {
    return (
      <div>
        {!!label && (
          <label
            htmlFor={name}
            className="mb-1 block text-xs font-semibold text-primary sm:text-sm"
          >
            {label}
          </label>
        )}
        <div className="relative flex rounded-md shadow-sm">
          {children && (
            <span className="inline-flex items-center rounded-l-md border border-r-0 border-solid border-purple-300 bg-purple-50 px-3 text-xs text-primary sm:text-sm">
              {children}
            </span>
          )}
          {suffixIcon && (
            <div
              className={classNames(
                'absolute inset-y-0 right-0 z-10 flex items-center pr-3',
                error ? 'text-danger' : 'text-primary'
              )}
            >
              {suffixIcon}
            </div>
          )}
          {error && !suffixIcon && (
            <div className="pointer-events-none absolute inset-y-0 right-0 z-10 flex items-center pr-3 text-danger">
              <Icon className="h-5 w-5" aria-hidden="true">
                <AlertOctagon />
              </Icon>
            </div>
          )}
          <input
            ref={ref}
            type="text"
            value={value}
            readOnly={readOnly}
            name={name}
            id={name}
            className={classNames(
              baseStyle,
              error ? invalidStyle : validStyle,
              className,
              children ? 'rounded-r-md' : 'rounded-md'
            )}
            placeholder={placeholder}
            aria-describedby={error ? `${name}-error` : undefined}
            aria-invalid={!!error}
            {...rest}
          />
        </div>
        {error && (
          <p
            className="mt-2 text-xs text-danger sm:text-sm"
            id={`${name}-error`}
          >
            {error}
          </p>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
