import React from 'react';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import InviteTeamOnboarding from 'src/components/InviteTeamOnboarding';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { BaseModal } from '../Modal';
import { Button } from '../Button';

type Props = {
  show: boolean;
  onClose: () => void;
};

const InviteTeamModal: React.FunctionComponent<Props> = ({
  show,
  onClose,
}): JSX.Element => {
  const { LL } = useI18nContext();
  return (
    <BaseModal
      show={show}
      onClose={onClose}
      panelClassName="bg-white rounded-xl relative"
    >
      <button
        className="absolute right-6 top-6 h-6 w-6 appearance-none text-text-secondary"
        onClick={onClose}
      >
        <Icon>
          <XClose />
        </Icon>
      </button>
      <div className="flex flex-col lg:grid lg:min-h-[478px] lg:grid-cols-2">
        <div className="flex flex-col justify-between rounded-t-xl bg-purple-100 px-5 pb-6 pt-8 text-primary lg:col-span-1 lg:rounded-l-xl lg:rounded-tr-none">
          <div className="relative flex w-max flex-col gap-3 lg:gap-6">
            <div>
              <div className="px-3">
                <h3 className="text-[24px] font-bold leading-8 text-primary">
                  {LL.inviteModal.title()}
                </h3>
                <p className="mt-3 max-w-xs text-sm text-primary lg:pr-8">
                  {LL.inviteModal.description()}
                </p>
              </div>
              <div
                aria-hidden="true"
                className="mt-4 hidden flex-col gap-5 rounded-lg border-2 border-solid border-[#D8CAFA] p-3.5 lg:flex"
              >
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-col gap-1">
                    <span className="text-xs font-semibold text-purple-800">
                      {LL.inviteModal.heroTitle()}
                    </span>
                    <span className="text-xxs font-semibold text-purple-800/60">
                      {LL.inviteModal.heroSubtitle()}
                    </span>
                  </div>

                  <div className="flex flex-row">
                    <img
                      alt=""
                      height="48"
                      width="48"
                      src="/images/invite_card_examples/avatar_example_1.jpeg"
                      className="z-10 h-6 w-6 rounded-full ring-2 ring-white drop-shadow-sm"
                    />
                    <img
                      alt=""
                      height="48"
                      width="48"
                      src="/images/invite_card_examples/avatar_example_2.jpeg"
                      className="h-6 w-6 rounded-full ring-2 ring-white drop-shadow-sm"
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-1">
                  <img
                    alt="MagicBrief example ad"
                    height="344"
                    width="205"
                    src="/images/invite_card_examples/card_example_1.jpeg"
                    className="w-28 rounded drop-shadow-md"
                  />
                  <img
                    alt="MagicBrief example ad"
                    height="344"
                    width="206"
                    src="/images/invite_card_examples/card_example_2.jpeg"
                    className="w-28 rounded drop-shadow-md"
                  />
                  <img
                    alt="MagicBrief example ad"
                    height="344"
                    width="206"
                    src="/images/invite_card_examples/card_example_3.jpeg"
                    className="w-28 rounded drop-shadow-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex max-w-[416px] flex-col px-5 pb-8 pt-6 lg:col-span-1 lg:py-10">
          <InviteTeamOnboarding isOnboarding={false} onComplete={onClose}>
            {({ isSubmitting }) => (
              <div className="flex flex-row gap-2 self-end">
                <Button variant="secondary" onClick={onClose}>
                  {LL.cancel()}
                </Button>
                <Button loading={isSubmitting} htmlType="submit">
                  {LL.inviteModal.sendInvites()}
                </Button>
              </div>
            )}
          </InviteTeamOnboarding>
        </div>
      </div>
    </BaseModal>
  );
};

export default InviteTeamModal;
