import { io, Socket } from 'socket.io-client';
import { appConfigs } from 'src/configs';
import type {
  EmitEvents,
  ListenEvents,
} from '@magicbrief/server/src/sockets/socketio';

if (!appConfigs.socketIOHost) {
  throw new Error('App built without VITE_SOCKET_IO_HOST');
}

export const socket: Socket<EmitEvents, ListenEvents> = io(
  appConfigs.socketIOHost,
  {
    path: import.meta.env.VITE_SOCKET_IO_PATH,
    autoConnect: false,
    transports: ['websocket'],
    secure: true,
  }
);
