import classNames from 'classnames';
import CheckCircle from 'src/assets/svgicons/solid/check-circle.svg';
import { Icon } from '../Icon';

type Props = React.PropsWithChildren<{
  plan: string;
  price: string;
  features: string[];
  background?: string;
  extrasPrice?: string;
}>;

const Plan: React.FC<Props> = ({
  plan,
  price,
  features,
  background,
  extrasPrice,
  children,
}): JSX.Element => {
  return (
    <div
      className={classNames(
        'text-primary p-4 border border-solid border-primary/20 rounded-xl w-full flex flex-col justify-between',
        background ? background : 'bg-white'
      )}
    >
      <div className="px-2 pb-1">
        <div className="flex justify-between">
          <div>{plan}</div>
          <div className="flex flex-col items-end">
            <div className="text-sm font-semibold text-text-secondary">
              {price}
            </div>
            {extrasPrice && (
              <div className="text-xxs font-medium text-text-secondary/70">
                {extrasPrice}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2 py-4">
          {features.map((feature) => {
            return (
              <div
                className="flex items-start lg:items-center gap-2"
                key={feature}
              >
                <Icon className="w-3.5 h-3.5 shrink-0 mt-[1px] lg:mt-0">
                  <CheckCircle />
                </Icon>
                <div className="text-xs text-primary/90 font-medium">
                  {feature}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default Plan;
