export const inviteCodeLocalStorageKey = 'inviteCodes';

/**
 * Removes a given invite code from local storage and returns the new value of
 * the invite codes in local storage.
 *
 * @param code The invite code to remove
 */

export function removeInviteCodeFromLocalStorage(
  code: string
): string[] | null {
  const json = localStorage.getItem(inviteCodeLocalStorageKey);
  const codes = json ? JSON.parse(json) : null;
  if (codes && Array.isArray(codes)) {
    const idx = codes.findIndex((x) => x === code);
    const newCodes = [...codes.slice(0, idx), ...codes.slice(idx + 1)];
    if (newCodes.length) {
      localStorage.setItem(inviteCodeLocalStorageKey, JSON.stringify(newCodes));
      return newCodes;
    } else {
      localStorage.removeItem(inviteCodeLocalStorageKey);
      return null;
    }
  }
  return null;
}

export function getInviteCodesFromLocalStorage(): string[] | null {
  const json = localStorage.getItem(inviteCodeLocalStorageKey);
  const codes = json ? JSON.parse(json) : null;
  return codes;
}
