import { useEffect, useRef } from 'react';
import { captureException } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { useSearchParams } from 'react-router-dom';
import { trpc } from 'src/lib/trpc';
import {
  clearLocalUTMData,
  retrieveUTMMetadataFromLocalStorage,
} from 'src/utils/utm';
import { AppWithNavLoader } from 'src/components/SuspenseLoaders/SuspenseLoaders';
import { useSetAlgoliaInsightsUserToken } from 'src/lib/algolia';
import { segment } from 'src/lib/segment';
import { useFirebaseContext } from '../FirebaseOutlet/useFirebaseContext';
import JoinOrg from '../RequireAuthenticatedOutlet/components/JoinOrg';
import UniversalModals from '../RequireAuthenticatedOutlet/components/UniversalModals';

function userHasCurrentCompany(orgId?: number | null) {
  return orgId != null && orgId > -1;
}

const InitialiseUserOutlet: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const user = useFirebaseContext();
  const inviteCode =
    localStorage.getItem('registrationInviteCode') || undefined;
  const referral = localStorage.getItem('referralID') || undefined;
  const utm = retrieveUTMMetadataFromLocalStorage();
  const initLock = useRef<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const queryClient = useQueryClient();
  const getUserQuery = trpc.user.getUser.useQuery();

  useSetAlgoliaInsightsUserToken(getUserQuery.data?.uuid ?? null);

  const refreshUser = async () => {
    await queryClient.invalidateQueries(
      getQueryKey(trpc.user.getUser, undefined, 'any')
    );
    await queryClient.invalidateQueries(
      getQueryKey(trpc.user.getUserInfo, undefined, 'any')
    );
  };

  const onJoinOrgSuccess = async () => {
    await refreshUser();
  };

  const initialiseMutation = trpc.user.initialise.useMutation({
    onSuccess: async (result) => {
      if (result) {
        // Trigger registration event in Segment

        if (result.newUser) {
          const updatedSearchParams = new URLSearchParams(
            searchParams.toString()
          );
          updatedSearchParams.set('first_landing', 'true');
          setSearchParams(updatedSearchParams);

          segment
            ?.track('completed_signup', {}, { userId: result.user.uuid })
            .then(() => {})
            .catch((e) => {
              console.error(e);
            });
        }
      }

      localStorage.removeItem('registrationInviteCode');
      clearLocalUTMData();
      localStorage.removeItem('referralID');
      await refreshUser();
    },
    onError: (err) => {
      captureException(err, (scope) => {
        scope.setLevel('error');
        scope.setTransactionName('First Time User Init');
        if (user) {
          scope.setUser({ id: user.uid, email: user.email ?? undefined });
        }
        return scope;
      });
    },
  });

  useEffect(() => {
    if (!initLock.current) {
      /** @todo this is temporary logic until we split up the steps */
      const shouldInitialiseUser =
        user &&
        user.emailVerified &&
        !getUserQuery.isLoading &&
        !userHasCurrentCompany(getUserQuery.data?.currentCompany);

      if (shouldInitialiseUser) {
        initLock.current = true;

        initialiseMutation.mutate({
          inviteCode,
          referral: referral ?? undefined,
          utm: new URLSearchParams(utm ?? '').toString(),
        });
      }
    }
  }, [user, inviteCode, initialiseMutation, referral, utm, getUserQuery]);

  const isLoading = initialiseMutation.isLoading || getUserQuery.isLoading;

  if (user === null) {
    return <>{children}</>;
  }

  const orgIdToJoin = initialiseMutation?.data?.orgIdToJoin ?? null;

  const userCanJoinOrg =
    !isLoading &&
    !userHasCurrentCompany(getUserQuery?.data?.currentCompany) &&
    orgIdToJoin != null;

  if (userCanJoinOrg) {
    return (
      <JoinOrg
        authUser={user}
        organisationId={orgIdToJoin}
        onSuccess={onJoinOrgSuccess}
      />
    );
  }

  if (isLoading) {
    return <AppWithNavLoader />;
  }

  return (
    <>
      <UniversalModals />
      {children}
    </>
  );
};

export default InitialiseUserOutlet;
