import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { User } from 'firebase/auth';
import { RouterProvider } from 'react-aria-components';
import { eventEmitter } from 'src/events';
import { auth } from 'src/lib/firebase';
import { AppWithNavLoader } from 'src/components/SuspenseLoaders/SuspenseLoaders';
import { FirebaseContext, FirebaseDispatchContext } from './context';

const FirebaseOutlet: React.FunctionComponent = (): JSX.Element => {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const navigate = useNavigate();
  const refreshUser = useCallback(async () => {
    await auth.currentUser?.reload();
    const currentUser = auth.currentUser;
    setUser(currentUser ? { ...currentUser } : currentUser);
    return currentUser;
  }, []);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdTokenResult(true);
        Sentry.setUser({
          id: user.uid,
          email: user.email ?? undefined,
          username: user.displayName ?? undefined,
        });

        if (token.claims.stripeRole === undefined) {
          // Refresh token
          await user.getIdTokenResult(true);
        }

        eventEmitter.emit('user:identified', user);
      }
      setUser(user ? { ...user } : user);
    });
    return unsub;
  }, []);

  return (
    <RouterProvider navigate={navigate}>
      <FirebaseContext.Provider value={user ?? null}>
        <FirebaseDispatchContext.Provider value={{ refreshUser }}>
          {user === undefined ? <AppWithNavLoader /> : <Outlet />}
        </FirebaseDispatchContext.Provider>
      </FirebaseContext.Provider>
    </RouterProvider>
  );
};

export default FirebaseOutlet;
