import { createContext, useContext, useMemo } from 'react';
import { useI18nContext } from 'src/i18n/i18n-react';
import { getFeatureFlagValue } from 'src/utils/getFeatureFlagValue';
import { trpc } from 'src/lib/trpc';

type NavigationLink = {
  to: string;
  label: string;
};

export function useDefaultNavigationLinks(): NavigationLink[] {
  const { LL } = useI18nContext();
  const user = trpc.user.getUserInfo.useQuery();

  const links = useMemo((): NavigationLink[] => {
    const isAssetsUiEnabled = getFeatureFlagValue('ASSETS_UI');
    const isInsightsUiEnabled =
      getFeatureFlagValue('INSIGHTS_UI_ENABLED') || user.data?.undiesUser;

    return [
      {
        to: '/library',
        label: LL.navigation.adLibrary(),
      },
      ...(isInsightsUiEnabled
        ? [
            {
              to: '/insights',
              label: LL.navigation.insights(),
            },
          ]
        : []),
      { to: '/briefs', label: LL.navigation.briefs() },
      ...(isAssetsUiEnabled
        ? [
            {
              to: '/assets',
              label: LL.navigation.assets(),
            },
          ]
        : []),
    ];
  }, [LL.navigation, user.data?.undiesUser]);
  return links;
}

type NavigationContextState = {
  isMobileMenuOpen: boolean;
};

export const NavigationContext = createContext<boolean>(false);

export function useNavigationContext(): NavigationContextState {
  const context = useContext(NavigationContext);
  const state = useMemo(
    (): NavigationContextState => ({
      isMobileMenuOpen: context,
    }),
    [context]
  );
  return state;
}
