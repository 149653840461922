import React, { useMemo, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from 'src/components/Icon';
import Input from 'src/components/Input';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import Spinner from 'src/components/Loaders/Spinner';
import { Alert } from 'src/components/Alert';
import { Button } from 'src/components/Button';
import Copy01 from 'src/assets/svgicons/duocolor/copy-01.svg';
import Link01 from 'src/assets/svgicons/duotone/link-01.svg';
import Mail01 from 'src/assets/svgicons/duotone/mail-01.svg';
import Plus from 'src/assets/svgicons/duocolor/plus.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import useAnalyticsEvent from 'src/utils/useAnalyticsEvent';
import { useCopyToClipboard } from 'src/utils/useCopyToClipboard';
import { segment } from 'src/lib/segment';

type FormData = {
  emails: { email: string }[];
};

type Props = {
  isOnboarding: boolean;
  onComplete: () => void;
  className?: string;
  children: (props: {
    hasEmails: boolean;
    isSubmitting: boolean;
  }) => React.ReactNode;
};

const InviteTeamOnboarding: React.FunctionComponent<Props> = ({
  isOnboarding,
  onComplete,
  className,
  children,
}): JSX.Element => {
  const { LL } = useI18nContext();
  const resolver = useMemo(
    () =>
      zodResolver(
        z.object({
          emails: z.array(
            z.object({
              email: z
                .string()
                .email(LL.errors.invalidEmail())
                .or(z.literal('')),
            })
          ),
        })
      ),
    [LL]
  );
  const { register, handleSubmit, control, formState, setError, watch } =
    useForm<FormData>({
      resolver,
      mode: 'onBlur',
      defaultValues: {
        emails: [{ email: '' }],
      },
    });
  const emails = watch('emails');
  const user = trpc.user.getUserInfo.useQuery();
  const organisation = trpc.organisation.getOrganisation.useQuery();
  const inviteByEmail =
    trpc.organisation.inviteUserToOrganisationByEmail.useMutation();
  const userEvent = useAnalyticsEvent();
  const [submitting, setSubmitting] = useState(false);
  const { fields, append, remove } = useFieldArray({ control, name: 'emails' });
  const copyToClipboard = useCopyToClipboard();

  const handleCopyLink = () => {
    const link = organisation.data?.links.find((x) => x.type === 'editor')
      ?.fullURL;

    if (link) {
      copyToClipboard(link);
      if (isOnboarding) {
        void segment?.track('invited_onboarding_link');
      } else {
        void segment?.track('invited_link');
      }
      void userEvent({
        eventName: 'workspace:invite_link_copied',
        metadata: {
          workspace_id: organisation.data?.orgIdentifier,
          role: 'editor',
          link,
        },
      });
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    setSubmitting(true);

    const emails = data.emails
      .filter((obj) => obj.email)
      .map((email) =>
        inviteByEmail.mutateAsync({ email: email.email, role: 'editor' })
      );
    if (emails.length === 0) {
      setError('emails', { message: LL.onboarding.errors.inviteNoEmails() });
      return;
    }
    try {
      await Promise.all(emails);
      if (isOnboarding) {
        void segment?.track('invited_onboarding_email', {
          emailsCount: emails.length,
        });
      } else {
        void segment?.track('invited_email', {
          emailsCount: emails.length,
        });
      }
      onComplete();
    } catch (error) {
      if (error instanceof Error) {
        setError('root', {
          message: LL.errors.genericWithDetail({ detail: error.message }),
        });
      } else {
        setError('root', {
          message: LL.errors.genericTitle(),
        });
      }
      return;
    }

    setSubmitting(false);
    toast.success(LL.onboarding.success.invitationsSent(), {
      className: 'toast-success ',
    });
  });

  if (user.isLoading || organisation.isLoading) {
    return (
      <div
        className={classNames(
          'flex items-center justify-center text-primary',
          className
        )}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <form
      className={classNames('flex flex-auto flex-row', className)}
      onSubmit={onSubmit}
    >
      {(user.isLoading ||
        organisation.isLoading ||
        user.isRefetching ||
        organisation.isRefetching) && (
        <div className="flex flex-auto items-center justify-center text-primary">
          <Spinner />
        </div>
      )}
      {(user.error || organisation.error) && (
        <div className="flex flex-auto flex-col justify-between">
          <Alert type="error">
            {user.error?.message ?? organisation.error?.message}
          </Alert>
          <div className="flex flex-row gap-3 self-end">
            <Button
              onClick={() => {
                void user.refetch();
                void organisation.refetch();
              }}
            >
              {LL.retry()}
            </Button>
          </div>
        </div>
      )}
      {user.data && organisation.data && (
        <div className="flex flex-auto flex-col justify-between gap-4 w-full">
          <div className="flex flex-col gap-4">
            <h5 className="block text-sm font-bold text-primary sm:text-lg">
              {LL.inviteYourTeam()}
            </h5>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-2 rounded-lg bg-purple-50 py-4">
                {formState.errors.root?.message && (
                  <Alert type="error">{formState.errors.root.message}</Alert>
                )}
                <h5 className="flex items-center gap-2 px-4 text-sm font-semibold text-primary">
                  <Icon className="w-4">
                    <Mail01 />
                  </Icon>
                  <span>{LL.labels.inviteByEmail()}</span>
                </h5>
                <div className="flex max-h-36 flex-col gap-2.5 overflow-auto scroll-smooth py-1">
                  {fields.map((field, index) => {
                    const error = formState.errors.emails
                      ? formState.errors.emails[index]
                      : null;
                    return (
                      <div
                        key={field.id}
                        className="ml-4 flex items-center justify-center group"
                      >
                        <div className="grow">
                          <Input
                            key={field.id}
                            {...register(`emails.${index}.email`)}
                            error={error?.email?.message}
                            label=""
                            type="email"
                            placeholder="email@example.com"
                            className="text-xs placeholder:text-xs"
                          />
                        </div>
                        <button
                          type="button"
                          className={classNames(
                            'h-4 w-4 appearance-none mx-1',
                            fields.length > 1 ? 'visible' : 'invisible'
                          )}
                          onClick={() => remove(index)}
                        >
                          <XClose className="cursor-pointer text-primary opacity-0 transition-opacity delay-75 duration-150 ease-out group-hover:opacity-100" />
                        </button>
                      </div>
                    );
                  })}
                </div>
                <Button
                  variant="custom"
                  size="smallest"
                  className="w-min text-xxs whitespace-nowrap font-semibold text-primary/70 !gap-0 -mt-1"
                  onClick={() => append({ email: '' })}
                  icon={
                    <Icon className="w-3 h-3 mb-[1px]">
                      <Plus />
                    </Icon>
                  }
                >
                  {LL.addAnother()}
                </Button>
              </div>
              <div className="bg-secondary/80 relative w-full h-[1px] my-2">
                <p className="text-xs m-auto bg-white px-3 uppercase text-center text-primary/40 font-semibold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  {LL.login.or()}
                </p>
              </div>
              <div className="flex flex-col gap-2 rounded-lg bg-purple-50 p-4">
                <h5 className="flex items-center gap-2 text-sm font-semibold text-primary">
                  <Icon className="w-4">
                    <Link01 />
                  </Icon>
                  <span>{LL.labels.copyInvitationLink()}</span>
                </h5>

                <div className="relative flex shadow-sm !w-full border border-solid border-purple-300 rounded-md form-input p-0">
                  <div className="w-10/12 lg:w-64 py-2 select-all text-xs p text-primary/90 pl-3">
                    <div className="overflow-x-clip whitespace-nowrap">
                      {organisation.data.links.find((x) => x.type === 'editor')
                        ?.fullURL ??
                        LL.workspaceSettings.errors.missingInviteLinkForRole()}
                    </div>
                  </div>
                  <span className="inline-flex items-center justify-center border-l border-solid border-purple-300 rounded-r-md bg-purple-50 text-primary grow">
                    <Button
                      size="small"
                      variant="custom"
                      onClick={handleCopyLink}
                      className="!p-0"
                    >
                      <Icon className="w-5 h-5">
                        <Copy01 />
                      </Icon>
                    </Button>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {children({
            hasEmails: !!emails.find((x) => !!x.email),
            isSubmitting: submitting,
          })}
        </div>
      )}
    </form>
  );
};

export default InviteTeamOnboarding;
