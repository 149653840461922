import React from 'react';
import classNames from 'classnames';
import Toolbar from 'src/components/Toolbar/Toolbar';

type Props = React.PropsWithChildren<{
  className?: string;
  title: React.ReactNode | null;
  mobileMenu: React.ReactNode | null;
  actions: React.ReactNode | null;
}>;

const BaseLibraryToolbar: React.FunctionComponent<Props> = ({
  className,
  mobileMenu,
  title,
  children,
  actions,
}): JSX.Element => {
  return (
    <Toolbar
      className={classNames('sticky z-100 py-3 flex flex-col gap-2', className)}
    >
      <div className="flex min-w-0 flex-auto flex-row justify-between gap-4 px-5 md:pl-10">
        <div className="flex min-w-0 flex-row items-center gap-4 text-primary xl:gap-8">
          {mobileMenu}
          <div className="flex min-w-0 flex-row items-center gap-3 font-semibold text-primary">
            {title}
          </div>
        </div>

        {actions}
      </div>

      {children}
    </Toolbar>
  );
};

export default BaseLibraryToolbar;
