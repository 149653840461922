import { createTRPCReact, httpBatchLink } from '@trpc/react-query';
import { createTRPCProxyClient } from '@trpc/client';
import superjson from 'superjson';
import { appConfigs } from 'src/configs';
import { auth } from './firebase';
import type { AppRouter } from '@magicbrief/server/src/trpc/router';

/** @todo remove when we migrate endpoint */
const getUrl = () => {
  const betaUrl = localStorage.getItem('beta:MB_API_URL');
  return betaUrl != null ? `${betaUrl}/trpc` : appConfigs.trpcHost ?? '';
};

export const trpc = createTRPCReact<AppRouter>();

export const trpcProxyClient = createTRPCProxyClient<AppRouter>({
  transformer: superjson,
  links: [
    httpBatchLink({
      url: getUrl(),
      async headers() {
        const firebaseToken = await auth.currentUser?.getIdTokenResult();
        const authToken = `Bearer ${firebaseToken?.token}`;
        return {
          Authorization: authToken,
        };
      },
    }),
  ],
});

export const trpcReactClient = trpc.createClient({
  transformer: superjson,
  links: [
    httpBatchLink({
      url: getUrl(),
      async headers() {
        const firebaseToken = await auth.currentUser?.getIdTokenResult();
        const authToken = `Bearer ${firebaseToken?.token}`;
        return {
          Authorization: authToken,
        };
      },
    }),
  ],
});
