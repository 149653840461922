import React, { useCallback, useState } from 'react';
import BaseModal from 'src/components/Modal/BaseModal';
import UpgradeModalBody from './components/UpgradeModalBody';

type Props = {
  show: boolean;
  onClose: (() => void) | null;
  hardPaywall?: boolean;
  title?: string;
};

const UpgradeModal: React.FunctionComponent<Props> = ({
  show,
  onClose,
  hardPaywall = false,
  title,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = useCallback((isSubmitting: boolean) => {
    setIsSubmitting(isSubmitting);
  }, []);

  return (
    <BaseModal
      overlayClassName="backdrop-blur-sm bg-gray-800/50 transition-all"
      onClose={!isSubmitting ? onClose : null}
      show={show}
      panelClassName="flex flex-col-reverse gap-8 md:gap-0 md:flex-row max-w-4xl overflow-hidden bg-white rounded-xl cursor-default"
    >
      <UpgradeModalBody
        isSubmitting={isSubmitting}
        setIsSubmitting={onSubmit}
        onClose={onClose}
        hardPaywall={hardPaywall}
        title={title}
      />
    </BaseModal>
  );
};

export default UpgradeModal;
