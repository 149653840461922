import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { captureException } from '@sentry/react';
import { trpc } from 'src/lib/trpc';
import { segment } from 'src/lib/segment';
import { useFirebaseContext } from '../FirebaseOutlet/useFirebaseContext';

const AnalyticsOutlet: React.FunctionComponent = (): JSX.Element => {
  const firebaseUser = useFirebaseContext();
  const location = useLocation();
  const user = trpc.user.getUserInfo.useQuery(undefined, {
    enabled: !!firebaseUser?.emailVerified,
  });
  const userUuid = user.data?.uuid;
  const userData = user.data;
  useEffect(() => {
    if (userUuid && userData) {
      if (window.Intercom) {
        window.Intercom('update', {
          email: userData.email,
          name: userData.name,
        });
      }
      segment
        ?.identify(userUuid, {
          name: userData.name,
          email: userData.email,
          firebaseID: userData.firebaseID,
          funnelState: userData.userStatus,
        })
        .catch((e) => {
          captureException(e, (scope) => {
            scope.setTransactionName('segmentUserIdentification');
            return scope;
          });
        });
    }
  }, [userUuid, userData]);

  useEffect(() => {
    segment?.page('Viewed Page').catch((e) => {
      captureException(e, (scope) => {
        scope.setTransactionName('segmentPageEvent');
        return scope;
      });
    });
  }, [location]);

  return <Outlet />;
};

export default AnalyticsOutlet;
