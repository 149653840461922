import { useEffect, useState } from 'react';
import { MBOrgBasics } from '@magicbrief/server/src/process/organisation';
import { toast } from 'react-toastify';
import { Icon } from 'src/components/Icon';
import { Button } from 'src/components/Button';
import { BaseModal } from 'src/components/Modal';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import {
  removeInviteCodeFromLocalStorage,
  getInviteCodesFromLocalStorage,
} from 'src/pages/Invite/util';
import Spinner from 'src/components/Loaders/Spinner';
import Mail02 from 'src/assets/svgicons/duocolor/mail-02.svg';
import useAnalyticsEvent from 'src/utils/useAnalyticsEvent';
import { segment } from 'src/lib/segment';

type State =
  | { status: 'idle' }
  | { status: 'loading' }
  | { status: 'success'; data: MBOrgBasics }
  | { status: 'error'; message: string };

type Props = {
  disabled: boolean;
};

const ManageInviteModal: React.FunctionComponent<Props> = ({
  disabled,
}): JSX.Element | null => {
  const { LL } = useI18nContext();
  const trpcContext = trpc.useContext();
  const addUserToOrg = trpc.newUser.addCurrentUserToOrganisation.useMutation();
  const [inviteCodes, setInviteCodes] = useState(
    getInviteCodesFromLocalStorage()
  );
  const [state, setState] = useState<State>({ status: 'idle' });
  const userEvent = useAnalyticsEvent();

  const firstInviteCode = inviteCodes?.length ? inviteCodes[0] : null;

  useEffect(() => {
    if (firstInviteCode && state.status === 'idle') {
      setState({ status: 'loading' });
      trpcContext.client.organisation.organisationInviteMetadata
        .query({
          invite_code: firstInviteCode,
        })
        .then((data) => {
          void userEvent({
            eventName: 'workspace:viewed',
            metadata: {
              invitation_code: firstInviteCode,
              org_name: data.name,
            },
          });
          setState({ status: 'success', data });
        })
        .catch((e: Error) => {
          setState({ status: 'error', message: e.message });
        });
    }
  }, [firstInviteCode, trpcContext, state, userEvent]);

  return (
    <BaseModal
      show={!disabled && !!inviteCodes}
      onClose={null}
      panelClassName="flex flex-col items-center p-6 max-w-[400px] bg-white rounded-xl"
    >
      <div className="mb-5 flex h-12 w-12 items-center justify-center rounded-full bg-purple-200 text-primary">
        <Icon>
          <Mail02 />
        </Icon>
      </div>
      {state.status === 'loading' && (
        <div className="flex flex-row items-center justify-center gap-4 text-primary">
          <Spinner />
          <span className="text-sm">{LL.inviteAlert.loading()}</span>
        </div>
      )}
      {state.status === 'success' && (
        <>
          <h2 className="text-center text-2xl font-bold text-primary">
            {LL.inviteAlert.title({ company: state.data.name })}
          </h2>
          <p className="mt-4 px-2 text-center text-sm font-normal text-purple-700">
            {LL.inviteAlert.description({ company: state.data.name })}
          </p>
          <div className="flex w-full flex-row gap-3 pt-6">
            <Button
              disabled={addUserToOrg.isLoading}
              className="basis-1/2"
              variant="secondary"
              onClick={() => {
                if (firstInviteCode) {
                  setInviteCodes(
                    removeInviteCodeFromLocalStorage(firstInviteCode)
                  );
                }
              }}
            >
              {LL.cancel()}
            </Button>
            <Button
              loading={addUserToOrg.isLoading}
              className="basis-1/2"
              onClick={() => {
                if (firstInviteCode) {
                  addUserToOrg
                    .mutateAsync({ invite_code: firstInviteCode })
                    .then(() => {
                      setInviteCodes(
                        removeInviteCodeFromLocalStorage(firstInviteCode)
                      );
                      void segment?.track('accepted_invite_workspace', {
                        newUser: false,
                      });
                      window.location.reload();
                    })
                    .catch((e: Error) => {
                      toast.error(
                        LL.inviteAlert.error({
                          company: state.data.name,
                          message: e.message,
                        }),
                        { className: 'toast-danger' }
                      );
                    });
                }
              }}
            >
              {LL.inviteAlert.accept()}
            </Button>
          </div>
        </>
      )}
    </BaseModal>
  );
};

export default ManageInviteModal;
