import {
  FC,
  lazy,
  PropsWithChildren,
  Suspense,
  useCallback,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { trpc } from 'src/lib/trpc';
import PresenceProvider from 'src/contexts/PresenceProvider';
import {
  AssetDetailContextParams,
  AssetDetailContextProvider,
} from './context';

const AssetDetailsModal = lazy(
  () => import('src/components/AssetDetailsModal/AssetDetailsModal')
);

type Props = PropsWithChildren;

const AssetDetailsOutlet: FC<Props> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const assetUuid = searchParams.get('asset-uuid');
  const view = searchParams.get('view');

  const userInfo = trpc.user.getUserInfo.useQuery();

  const [state, setState] = useState<AssetDetailContextParams | null>(null);

  const show = useCallback(
    (params: AssetDetailContextParams) => {
      const queryString = window.location.search;
      const updatedSearchParams = new URLSearchParams(queryString);
      updatedSearchParams.set('asset-uuid', params.uuid);

      setState(params);
      setSearchParams(updatedSearchParams);
    },
    [setSearchParams]
  );

  const close = useCallback(() => {
    if (assetUuid) {
      const baseParams = new URLSearchParams({ 'asset-uuid': assetUuid });
      history.replaceState(
        null,
        '',
        `${window.location.pathname}?${baseParams.toString()}`
      );
    }
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.delete('asset-uuid');
    setSearchParams(updatedSearchParams);
    setState(null);
  }, [searchParams, setSearchParams, assetUuid]);

  const assetQuery = trpc.storage.getUserAssetByUUID.useQuery(
    {
      uuid: assetUuid ?? '',
    },
    { enabled: !!assetUuid }
  );

  const asset =
    assetQuery.data ??
    (state?.data?.asset.uuid === assetUuid ? state.data.asset : null);

  return (
    <AssetDetailContextProvider value={show}>
      {asset && (
        <Suspense>
          <PresenceProvider
            entityType="Organisation"
            entityUuid={userInfo.data?.currentOrganisationUuid ?? undefined}
          >
            <AssetDetailsModal
              asset={asset}
              afterClose={close}
              sidebarAsComments={
                !!state?.sidebarAsComments || view === 'content'
              }
            />
          </PresenceProvider>
        </Suspense>
      )}
      {children}
    </AssetDetailContextProvider>
  );
};

export default AssetDetailsOutlet;
