import React, { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import CheckCircle from 'src/assets/svgicons/duocolor/check-circle.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import Lightbulb03 from 'src/assets/svgicons/duocolor/lightbulb-03.svg';
import Link01 from 'src/assets/svgicons/duocolor/link-01.svg';
import File05 from 'src/assets/svgicons/duocolor/file-05.svg';
import { trpc } from 'src/lib/trpc';
import Spinner from 'src/components/Loaders/Spinner';
import useAnalyticsEvent from 'src/utils/useAnalyticsEvent';
import { FEATURES_LINK } from 'src/static/links';
import { segment } from 'src/lib/segment';
import ModalHeader from '../../Modal/components/ModalHeader';
import { BaseButton, ButtonLink } from '../../Button';
import { Alert } from '../../Alert';
import brands from './brands.jpg';
import Testimonial from './Testimonial';

type Props = {
  setIsSubmitting: (isSubmitting: boolean) => void;
  isSubmitting: boolean;
  onClose: (() => void) | null;
  hardPaywall?: boolean;
  title?: string;
};

// Separate out the modal body due to the way we request and generate Stripe subscriptions, because Modal
// children are not mounted until the modal is shown, we query the subscription here, so it is not
// triggered until the modal opens.
const UpgradeModalBody: React.FunctionComponent<Props> = ({
  isSubmitting,
  onClose,
  hardPaywall = false,
  title,
}): JSX.Element => {
  const { LL } = useI18nContext();
  const location = useLocation();

  const subscription = trpc.stripe.getSubscription.useQuery(
    { location: location.pathname },
    {
      staleTime: 1000 * 60 * 15, // 15 minute stale time, then re-query
      onError(e) {
        Sentry.captureException(e);
      },
    }
  );
  const userEvent = useAnalyticsEvent();
  const userEventTracked = useRef(false);

  useEffect(() => {
    if (!userEventTracked.current) {
      if (hardPaywall) {
        void userEvent({
          eventName: 'quota_paywall_viewed',
        });
      } else {
        void userEvent({
          eventName: 'paywall_dismissable_viewed',
        });
      }
      userEventTracked.current = true;
    }
  }, [hardPaywall, userEvent]);

  return (
    <>
      <div className="flex grow-0 basis-1/2 flex-col gap-7 bg-purple-50 p-6 text-primary sm:p-9">
        <div className="flex flex-col gap-6 text-sm">
          <ModalHeader>
            <span className="text-lg sm:text-2xl">
              {title ??
                (hardPaywall
                  ? LL.upgrade.paywalledTitle()
                  : LL.upgrade.title())}
            </span>
          </ModalHeader>
          <div
            className={classNames(
              subscription.isLoading
                ? 'animate-pulse rounded-lg bg-purple-300'
                : '',
              'cursor-default relative z-0 flex w-min transition-colors duration-150 ease-linear'
            )}
          >
            <div
              className={classNames(
                subscription.isLoading
                  ? 'invisible opacity-0'
                  : 'visible opacity-100',
                'absolute z-10 flex h-full grow flex-col justify-between py-3 pl-7 transition-opacity duration-150 ease-linear'
              )}
            >
              <div>
                <div className="text-lg font-semibold leading-7 text-purple-800">
                  {LL.upgrade.proPlan()}
                </div>

                <div
                  className={classNames(
                    subscription.isLoading
                      ? 'h-6 w-[11ex] animate-pulse rounded-lg bg-purple-300'
                      : 'bg-none',
                    'pt-1 text-base font-semibold leading-7 text-text-secondary'
                  )}
                >
                  {subscription.data?.type === 'payable' ? (
                    <span>
                      {LL.upgrade.priceMo({
                        dollars: subscription.data.subtotal / 100,
                      })}
                    </span>
                  ) : null}
                </div>
              </div>
              {subscription.data?.type === 'payable' && (
                <div className="text-xs font-normal leading-7 text-text-secondary">
                  {LL.upgrade.perSeatBilled({
                    dollars: subscription.data.perSeatUnit / 100,
                  })}
                </div>
              )}
            </div>

            <div
              className={classNames(
                subscription.isLoading
                  ? 'invisible opacity-0'
                  : 'visible opacity-100',
                'absolute text-center uppercase h-full right-0 z-10 rotate-180 text-xs leading-5 text-text-secondary/40 transition-opacity duration-150 ease-linear vertical-writing-rl orientation-mixed'
              )}
            >
              {LL.upgrade.ticketStub()}
            </div>

            <svg
              width="336"
              height="126"
              viewBox="0 0 336 126"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classNames(
                'transition-opacity duration-150 ease-linear scale-x-110 ml-4',
                subscription.isLoading ? 'opacity-0' : 'opacity-100 drop-shadow'
              )}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 1H281.926C283.295 1 284.442 1.96506 285.151 3.13615C286.552 5.45207 289.095 7 292 7C294.905 7 297.448 5.45207 298.849 3.13615C299.558 1.96506 300.705 1 302.074 1H327C331.418 1 335 4.58172 335 9V117C335 121.418 331.418 125 327 125H302.074C300.705 125 299.558 124.035 298.849 122.864C297.448 120.548 294.905 119 292 119C289.095 119 286.552 120.548 285.151 122.864C284.442 124.035 283.295 125 281.926 125H8.99999C4.58171 125 1 121.418 1 117V9C1 4.58172 4.58172 1 9 1Z"
                fill="white"
              />
              <path
                d="M298.849 122.864L299.277 122.605L298.849 122.864ZM298.849 3.13615L299.277 3.39503L298.849 3.13615ZM285.151 122.864L284.723 122.605L285.151 122.864ZM285.151 3.13615L284.723 3.39503L285.151 3.13615ZM281.926 0.5H9V1.5H281.926V0.5ZM292 6.5C289.278 6.5 286.893 5.04976 285.579 2.87727L284.723 3.39503C286.211 5.85439 288.913 7.5 292 7.5V6.5ZM298.421 2.87727C297.107 5.04976 294.722 6.5 292 6.5V7.5C295.087 7.5 297.789 5.85439 299.277 3.39503L298.421 2.87727ZM327 0.5H302.074V1.5H327V0.5ZM335.5 9C335.5 4.30558 331.694 0.5 327 0.5V1.5C331.142 1.5 334.5 4.85786 334.5 9H335.5ZM335.5 117V9H334.5V117H335.5ZM327 125.5C331.694 125.5 335.5 121.694 335.5 117H334.5C334.5 121.142 331.142 124.5 327 124.5V125.5ZM302.074 125.5H327V124.5H302.074V125.5ZM292 119.5C294.722 119.5 297.107 120.95 298.421 123.123L299.277 122.605C297.789 120.146 295.087 118.5 292 118.5V119.5ZM285.579 123.123C286.893 120.95 289.278 119.5 292 119.5V118.5C288.913 118.5 286.211 120.146 284.723 122.605L285.579 123.123ZM8.99999 125.5H281.926V124.5H8.99999V125.5ZM0.5 117C0.5 121.694 4.30557 125.5 8.99999 125.5V124.5C4.85786 124.5 1.5 121.142 1.5 117H0.5ZM0.5 9V117H1.5V9H0.5ZM9 0.5C4.30558 0.5 0.5 4.30558 0.5 9H1.5C1.5 4.85786 4.85786 1.5 9 1.5V0.5ZM302.074 124.5C300.952 124.5 299.94 123.7 299.277 122.605L298.421 123.123C299.176 124.369 300.458 125.5 302.074 125.5V124.5ZM299.277 3.39503C299.94 2.29956 300.952 1.5 302.074 1.5V0.5C300.458 0.5 299.176 1.63056 298.421 2.87727L299.277 3.39503ZM284.723 122.605C284.06 123.7 283.048 124.5 281.926 124.5V125.5C283.542 125.5 284.824 124.369 285.579 123.123L284.723 122.605ZM281.926 1.5C283.048 1.5 284.06 2.29956 284.723 3.39503L285.579 2.87727C284.824 1.63056 283.542 0.5 281.926 0.5V1.5Z"
                fill="#CEBFFF"
              />
              <line
                x1="291.5"
                y1="11.5"
                x2="291.5"
                y2="114.5"
                stroke="#7C63CE"
                strokeOpacity="0.6"
                strokeLinecap="round"
                strokeDasharray="3 4"
              />
              <path
                d="M1 9C1 4.58172 4.58172 1 9 1H12V125H9C4.58172 125 1 121.418 1 117V9Z"
                fill="url(#paint0_linear_4091_26455)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_4091_26455"
                  x1="6.5"
                  y1="1"
                  x2="6.5"
                  y2="125"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#8743FD" />
                  <stop offset="1" stopColor="#682EF8" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="grid grid-cols-7 gap-y-5 gap-x-10 text-sm">
          <div className="bg-purple-200 flex items-center justify-center p-2 w-10 h-10 rounded-lg mt-1 col-span-1">
            <Icon>
              <Lightbulb03 />
            </Icon>
          </div>
          <div className="col-span-6">
            <p className="font-semibold text-lg">
              {LL.upgrade.feature1.heading()}
            </p>
            <p className="text-primary/60 leading-relaxed text-sm font-normal">
              {LL.upgrade.feature1.text()}
            </p>
          </div>
          <div className="bg-purple-200 flex items-center justify-center p-2 w-10 h-10 rounded-lg mt-1 col-span-1">
            <Icon>
              <Link01 />
            </Icon>
          </div>
          <div className="col-span-6">
            <p className="font-semibold text-lg">
              {LL.upgrade.feature2.heading()}
            </p>
            <p className="text-primary/60 leading-relaxed text-sm font-normal">
              {LL.upgrade.feature2.text()}
            </p>
          </div>
          <div className="bg-purple-200 flex items-center justify-center p-2 col-span-1 w-10 h-10 rounded-lg mt-1">
            <Icon>
              <File05 />
            </Icon>
          </div>
          <div className="col-span-6">
            <p className="font-semibold text-lg">
              {LL.upgrade.feature3.heading()}
            </p>
            <p className="text-primary/60 leading-relaxed text-sm font-normal">
              {LL.upgrade.feature3.text()}
            </p>
            <ButtonLink
              type="external"
              href={FEATURES_LINK}
              variant="text"
              className="underline -ml-3 w-min whitespace-nowrap mt-3 font-medium underline-offset-2"
            >
              {LL.upgrade.viewAllFeatures()}
            </ButtonLink>
          </div>
        </div>
      </div>
      <div className="relative flex shrink-0 basis-1/2 flex-col justify-between gap-6 p-6 sm:p-9">
        <BaseButton
          disabled={isSubmitting || !onClose}
          onClick={onClose ?? undefined}
          className="absolute right-4 top-4 text-text-secondary transition-opacity disabled:opacity-0"
          icon={
            <Icon>
              <XClose />
            </Icon>
          }
        />
        <div className="flex h-full flex-col justify-center">
          {(subscription.isLoading || subscription.isFetching) && (
            <div className="flex items-center justify-center text-primary">
              <Spinner />
            </div>
          )}
          {!subscription.isLoading &&
            !subscription.isFetching &&
            subscription.data?.type === 'payable' && (
              <div className="flex flex-col h-full items-center gap-6 pt-12">
                <div className="text-primary/70 font-normal text-md">
                  {LL.upgrade.joinTeamsBuildingBetterAds()}
                </div>
                <img
                  src={brands}
                  className="rounded-lg px-4"
                  alt="Teams building better ads with MagicBrief"
                />

                <Testimonial className="border border-solid border-secondary shadow-md text-base md:text-md font-normal p-5" />

                <div className="flex items-center gap-3 -mt-2">
                  <svg
                    width="93"
                    height="16"
                    viewBox="0 0 93 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.77321 1.67191C8.51367 1.54816 8.21213 1.54816 7.95258 1.67191C7.72821 1.77889 7.6045 1.96464 7.54211 2.06654C7.47762 2.17188 7.41141 2.30607 7.34688 2.43687L5.97672 5.21269L2.91185 5.66066C2.76758 5.68172 2.61954 5.70332 2.49947 5.7322C2.38331 5.76013 2.16854 5.82054 1.99762 6.00095C1.79989 6.20966 1.7069 6.49645 1.74454 6.78147C1.77708 7.02785 1.91554 7.20279 1.99321 7.29357C2.07349 7.38741 2.18069 7.49178 2.28516 7.59349L4.50197 9.75266L3.9789 12.8024C3.95421 12.9462 3.92888 13.0937 3.91913 13.2169C3.9097 13.336 3.90058 13.559 4.01924 13.7775C4.1565 14.0302 4.40049 14.2075 4.68325 14.2599C4.9277 14.3052 5.13699 14.2276 5.24735 14.1818C5.36147 14.1344 5.49394 14.0647 5.62307 13.9968L8.3629 12.5559L11.1027 13.9968C11.2319 14.0647 11.3643 14.1344 11.4784 14.1818C11.5888 14.2276 11.7981 14.3052 12.0425 14.2599C12.3253 14.2075 12.5693 14.0302 12.7066 13.7775C12.8252 13.559 12.8161 13.336 12.8067 13.2169C12.7969 13.0937 12.7716 12.9462 12.7469 12.8024L12.2238 9.75266L14.4407 7.59346C14.5451 7.49176 14.6523 7.3874 14.7326 7.29357C14.8103 7.20279 14.9487 7.02785 14.9813 6.78147C15.0189 6.49645 14.9259 6.20966 14.7282 6.00095C14.5573 5.82054 14.3425 5.76013 14.2263 5.7322C14.1063 5.70332 13.9582 5.68172 13.8139 5.66066L10.7491 5.21269L9.37894 2.43692C9.3144 2.30611 9.24818 2.17189 9.18368 2.06654C9.1213 1.96464 8.99759 1.77889 8.77321 1.67191Z"
                      fill="#F59E0B"
                    />
                    <path
                      d="M27.8416 1.67191C27.582 1.54816 27.2805 1.54816 27.0209 1.67191C26.7966 1.77889 26.6729 1.96464 26.6105 2.06654C26.546 2.17188 26.4798 2.30607 26.4152 2.43687L25.0451 5.21269L21.9802 5.66066C21.8359 5.68172 21.6879 5.70332 21.5678 5.7322C21.4517 5.76013 21.2369 5.82054 21.066 6.00095C20.8682 6.20966 20.7753 6.49645 20.8129 6.78147C20.8454 7.02785 20.9839 7.20279 21.0616 7.29357C21.1419 7.38741 21.249 7.49178 21.3535 7.59349L23.5703 9.75266L23.0473 12.8024C23.0226 12.9462 22.9972 13.0937 22.9875 13.2169C22.9781 13.336 22.9689 13.559 23.0876 13.7775C23.2249 14.0302 23.4689 14.2075 23.7516 14.2599C23.9961 14.3052 24.2054 14.2276 24.3157 14.1818C24.4298 14.1344 24.5623 14.0647 24.6914 13.9968L27.4313 12.5559L30.1711 13.9968C30.3002 14.0647 30.4327 14.1344 30.5468 14.1818C30.6572 14.2276 30.8665 14.3052 31.1109 14.2599C31.3937 14.2075 31.6377 14.0302 31.7749 13.7775C31.8936 13.559 31.8845 13.336 31.875 13.2169C31.8653 13.0937 31.84 12.9462 31.8153 12.8024L31.2922 9.75266L33.509 7.59346C33.6135 7.49176 33.7207 7.3874 33.8009 7.29357C33.8786 7.20279 34.0171 7.02785 34.0496 6.78147C34.0873 6.49645 33.9943 6.20966 33.7965 6.00095C33.6256 5.82054 33.4108 5.76013 33.2947 5.7322C33.1746 5.70332 33.0266 5.68172 32.8823 5.66066L29.8174 5.21269L28.4473 2.43692C28.3828 2.30611 28.3165 2.17189 28.252 2.06654C28.1897 1.96464 28.0659 1.77889 27.8416 1.67191Z"
                      fill="#F59E0B"
                    />
                    <path
                      d="M46.908 1.67191C46.6484 1.54816 46.3469 1.54816 46.0873 1.67191C45.863 1.77889 45.7393 1.96464 45.6769 2.06654C45.6124 2.17188 45.5462 2.30607 45.4816 2.43687L44.1115 5.21269L41.0466 5.66066C40.9023 5.68172 40.7543 5.70332 40.6342 5.7322C40.5181 5.76013 40.3033 5.82054 40.1324 6.00095C39.9347 6.20966 39.8417 6.49645 39.8793 6.78147C39.9118 7.02785 40.0503 7.20279 40.128 7.29357C40.2083 7.38741 40.3155 7.49178 40.4199 7.59349L42.6367 9.75266L42.1137 12.8024C42.089 12.9462 42.0636 13.0937 42.0539 13.2169C42.0445 13.336 42.0353 13.559 42.154 13.7775C42.2913 14.0302 42.5353 14.2075 42.818 14.2599C43.0625 14.3052 43.2718 14.2276 43.3821 14.1818C43.4962 14.1344 43.6287 14.0647 43.7578 13.9968L46.4977 12.5559L49.2375 13.9968C49.3666 14.0647 49.4991 14.1344 49.6132 14.1818C49.7236 14.2276 49.9329 14.3052 50.1773 14.2599C50.4601 14.2075 50.7041 14.0302 50.8413 13.7775C50.96 13.559 50.9509 13.336 50.9414 13.2169C50.9317 13.0937 50.9064 12.9462 50.8817 12.8024L50.3586 9.75266L52.5754 7.59346C52.6799 7.49176 52.7871 7.3874 52.8674 7.29357C52.945 7.20279 53.0835 7.02785 53.116 6.78147C53.1537 6.49645 53.0607 6.20966 52.8629 6.00095C52.692 5.82054 52.4773 5.76013 52.3611 5.7322C52.241 5.70332 52.093 5.68172 51.9487 5.66066L48.8838 5.21269L47.5137 2.43692C47.4492 2.30611 47.3829 2.17189 47.3184 2.06654C47.2561 1.96464 47.1324 1.77889 46.908 1.67191Z"
                      fill="#F59E0B"
                    />
                    <path
                      d="M65.9763 1.67191C65.7168 1.54816 65.4153 1.54816 65.1557 1.67191C64.9313 1.77889 64.8076 1.96464 64.7452 2.06654C64.6807 2.17188 64.6145 2.30607 64.55 2.43687L63.1798 5.21269L60.115 5.66066C59.9707 5.68172 59.8227 5.70332 59.7026 5.7322C59.5864 5.76013 59.3717 5.82054 59.2007 6.00095C59.003 6.20966 58.91 6.49645 58.9477 6.78147C58.9802 7.02785 59.1187 7.20279 59.1963 7.29357C59.2766 7.38741 59.3838 7.49178 59.4883 7.59349L61.7051 9.75266L61.182 12.8024C61.1573 12.9462 61.132 13.0937 61.1223 13.2169C61.1128 13.336 61.1037 13.559 61.2224 13.7775C61.3596 14.0302 61.6036 14.2075 61.8864 14.2599C62.1308 14.3052 62.3401 14.2276 62.4505 14.1818C62.5646 14.1344 62.6971 14.0647 62.8262 13.9968L65.566 12.5559L68.3059 13.9968C68.435 14.0647 68.5675 14.1344 68.6816 14.1818C68.7919 14.2276 69.0012 14.3052 69.2457 14.2599C69.5284 14.2075 69.7724 14.0302 69.9097 13.7775C70.0283 13.559 70.0192 13.336 70.0098 13.2169C70 13.0937 69.9747 12.9462 69.95 12.8024L69.4269 9.75266L71.6438 7.59346C71.7483 7.49176 71.8554 7.3874 71.9357 7.29357C72.0134 7.20279 72.1518 7.02785 72.1844 6.78147C72.222 6.49645 72.129 6.20966 71.9313 6.00095C71.7604 5.82054 71.5456 5.76013 71.4295 5.7322C71.3094 5.70332 71.1613 5.68172 71.0171 5.66066L67.9522 5.21269L66.5821 2.43692C66.5175 2.30611 66.4513 2.17189 66.3868 2.06654C66.3244 1.96464 66.2007 1.77889 65.9763 1.67191Z"
                      fill="#F59E0B"
                    />
                    <path
                      d="M85.0447 1.67191C84.7852 1.54816 84.4836 1.54816 84.2241 1.67191C83.9997 1.77889 83.876 1.96464 83.8136 2.06654C83.7491 2.17188 83.6829 2.30607 83.6184 2.43687L82.2482 5.21269L79.1833 5.66066C79.0391 5.68172 78.891 5.70332 78.771 5.7322C78.6548 5.76013 78.44 5.82054 78.2691 6.00095C78.0714 6.20966 77.9784 6.49645 78.016 6.78147C78.0486 7.02785 78.187 7.20279 78.2647 7.29357C78.345 7.38741 78.4522 7.49178 78.5566 7.59349L80.7735 9.75266L80.2504 12.8024C80.2257 12.9462 80.2004 13.0937 80.1906 13.2169C80.1812 13.336 80.1721 13.559 80.2907 13.7775C80.428 14.0302 80.672 14.2075 80.9547 14.2599C81.1992 14.3052 81.4085 14.2276 81.5188 14.1818C81.633 14.1344 81.7654 14.0647 81.8946 13.9968L84.6344 12.5559L87.3742 13.9968C87.5033 14.0647 87.6358 14.1344 87.7499 14.1818C87.8603 14.2276 88.0696 14.3052 88.314 14.2599C88.5968 14.2075 88.8408 14.0302 88.978 13.7775C89.0967 13.559 89.0876 13.336 89.0782 13.2169C89.0684 13.0937 89.0431 12.9462 89.0184 12.8024L88.4953 9.75266L90.7122 7.59346C90.8166 7.49176 90.9238 7.3874 91.0041 7.29357C91.0817 7.20279 91.2202 7.02785 91.2527 6.78147C91.2904 6.49645 91.1974 6.20966 90.9997 6.00095C90.8287 5.82054 90.614 5.76013 90.4978 5.7322C90.3777 5.70332 90.2297 5.68172 90.0854 5.66066L87.0206 5.21269L85.6504 2.43692C85.5859 2.30611 85.5197 2.17189 85.4552 2.06654C85.3928 1.96464 85.2691 1.77889 85.0447 1.67191Z"
                      fill="#F59E0B"
                    />
                  </svg>
                  <div className="uppercase tracking-wider text-[10px] px-2 py-1 text-primary/60 font-bold bg-secondary/40 rounded-full">
                    {LL.upgrade.starRating()}
                  </div>
                </div>
                <div className="w-full text-center pt-2">
                  <ButtonLink
                    type="external"
                    href={subscription.data.redirectURL}
                    variant="gradient"
                    target="_self"
                    className="w-full"
                    onClick={() => {
                      void segment?.track('viewed_checkout');
                    }}
                  >
                    {LL.upgrade.continue()}
                  </ButtonLink>
                  <div className="text-xs text-primary/60 mt-3">
                    {LL.upgrade.refundCancelAnytime()}
                  </div>
                </div>
              </div>
            )}
          {subscription.data?.type === 'paid' && (
            <Alert
              type="success"
              icon={
                <Icon>
                  <CheckCircle />
                </Icon>
              }
            >
              {LL.upgrade.alreadyUpgraded()}
            </Alert>
          )}
        </div>
      </div>
    </>
  );
};

export default UpgradeModalBody;
