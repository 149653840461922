import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { getQueryParam } from 'src/utils/queryParams';
import { sanitizeRedirectPath } from 'src/utils/santitizePath';
import { disallowedRedirectPaths } from './consts';

export const Redirector: FC = () => {
  const redirectParam = getQueryParam('redirect');
  const firstLandingParam = getQueryParam('first_landing');

  const baseHomePath = '/library/discover';
  const homePath =
    firstLandingParam === 'true'
      ? `${baseHomePath}?first_landing=true`
      : baseHomePath;

  if (redirectParam != null) {
    const redirectPath = decodeURIComponent(
      sanitizeRedirectPath(redirectParam)
    );

    return (
      <Navigate
        to={
          disallowedRedirectPaths.includes(redirectParam)
            ? homePath
            : redirectPath
        }
      />
    );
  }

  return <Navigate to={homePath} />;
};
