import React, {
  PropsWithChildren,
  Fragment,
  JSXElementConstructor,
  ElementType,
} from 'react';
import { Popover as HeadlessPopover } from '@headlessui/react';
import { Float, FloatProps } from '@headlessui-float/react';
import classNames from 'classnames';

type PassthruPopoverProps<
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<unknown>,
> = Omit<Parameters<typeof HeadlessPopover<T>>[0], 'ref'>;

type PopoverProps<T extends ElementType> = PropsWithChildren<
  {
    asChildren?: PassthruPopoverProps<T>['children'];
    panelClassName?: string;
    placement?: FloatProps['placement'];
    onHide?: () => void;
  } & Omit<PassthruPopoverProps<T>, 'children'>
>;

export default function Popover<T extends React.ElementType = 'button'>({
  as,
  children,
  asChildren,
  panelClassName,
  placement,
  onHide,
  ...rest
}: PopoverProps<T>): JSX.Element {
  return (
    <HeadlessPopover as="div" className="relative">
      <Float
        flip
        shift
        portal
        tailwindcssOriginClass
        offset={8}
        as={Fragment}
        placement={placement}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        onHide={onHide}
      >
        <HeadlessPopover.Button as={as} {...rest}>
          {asChildren}
        </HeadlessPopover.Button>
        <HeadlessPopover.Panel
          className={classNames(
            'z-10 rounded-xl border border-solid border-purple-300 bg-white shadow-md focus:outline-none',
            panelClassName
          )}
        >
          {children}
        </HeadlessPopover.Panel>
      </Float>
    </HeadlessPopover>
  );
}
