import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PermissibleEntityType } from '@magicbrief/prisma/generated/client2';
import {
  Presence,
  PresenceResponse,
} from '@magicbrief/server/src/presence/@types';
import { mergeDeep } from '@magicbrief/common';
import { trpc } from 'src/lib/trpc';
import { socket } from 'src/lib/socketio';
import { useFirebaseContext } from 'src/pages/outlets/FirebaseOutlet/useFirebaseContext';

type UsePresenceState = {
  presences: PresenceResponse;
  currentUserPresence: Presence | null;
  getPresence: (
    userUuid: string,
    entityType: PermissibleEntityType,
    entityUuid: string
  ) => Presence | null;
};

type UsePresenceProps = {
  entityType: PermissibleEntityType;
  entityUuid?: string;
};

export function usePresence({
  entityType,
  entityUuid,
}: UsePresenceProps): UsePresenceState {
  const firebaseUser = useFirebaseContext();
  const userInfo = trpc.user.getUserInfo.useQuery(undefined, {
    enabled: !!firebaseUser,
  });
  const [presences, setPresences] = useState({});

  useEffect(() => {
    socket.on('presencesMutated', (next: PresenceResponse) => {
      if (next != null && Object.keys(next).length === 0) {
        return;
      }
      setPresences((current) => mergeDeep(current, next));
    });

    if (entityUuid != null && userInfo.data != null) {
      socket.emit('presenceConnect', entityType, entityUuid);
    }

    // re-kick presence connection if user reconnects
    socket.on('connect', () => {
      if (entityUuid != null && userInfo.data != null)
        socket.emit('presenceConnect', entityType, entityUuid);
    });

    socket.on('disconnect', () => {
      if (entityUuid != null && userInfo.data != null)
        socket.emit('presenceDisconnect', entityType, entityUuid);
    });

    return () => {
      if (entityUuid != null && userInfo.data != null)
        socket.emit('presenceDisconnect', entityType, entityUuid);
    };
  }, [entityType, entityUuid, userInfo.data]);

  const getPresence = useCallback(
    (userUuid: string, entityType: PermissibleEntityType, entityUuid: string) =>
      getPresenceForUserByEntity(presences, userUuid, entityType, entityUuid),
    [presences]
  );

  const currentUserPresence = useMemo(
    () =>
      userInfo.data != null && entityUuid != null
        ? getPresenceForUserByEntity(
            presences,
            userInfo.data.uuid,
            entityType,
            entityUuid
          )
        : null,
    [presences, userInfo.data, entityType, entityUuid]
  );

  return { presences, currentUserPresence, getPresence };
}

type PresenceContextState = {
  presences: PresenceResponse;
  currentUserPresence: Presence | null;
};

export const PresenceContext = createContext<PresenceContextState>({
  presences: {},
  currentUserPresence: null,
});

export const getPresenceForUserByEntity = (
  presences: PresenceResponse,
  userUuid: string,
  entityType: PermissibleEntityType,
  entityUuid: string
) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    presences?.[`${entityType}|${entityUuid}`]?.[
      `User|${userUuid}` as string
    ] ?? null
  );
};
