import { FC } from 'react';
import { Navigate } from 'react-router-dom';

export type Params = {
  to: string;
};

export const RedirectWithQueryParams: FC<Params> = ({ to }) => {
  const currentParams = location.search;

  return <Navigate to={to + currentParams} />;
};
