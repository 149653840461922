import { useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/react';
import { sentryConfigs } from 'src/configs';
import appConfig from '../configs/app';

export function initSentry() {
  Sentry.init({
    dsn: appConfig.env === 'development' ? undefined : sentryConfigs.dsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: 0.5,
    release: appConfig.release,
    environment: appConfig.deployment,
  });
}
