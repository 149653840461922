import type { PermissionRole } from '@magicbrief/prisma/generated/client2';

export const allRoles: Array<PermissionRole> = [
  'wizard',
  'superuser',
  'owner',
  'admin',
  'editor',
  'viewer',
  'unset',
];

/** @deprecated */
export enum CompanyTypeV1 {
  Agency = 'agency',
  Brand = 'brand',
  NGO = 'ngo',
  Contractor = 'contractor',
  Government = 'gov',
  Other = 'other',
}

/** @deprecated */
export enum CompanyTypeV2 {
  Startup = 'startupSaaS',
  Ecommerce = 'ecommerce',
  Education = 'education',
  Agency = 'agency',
  Influencer = 'bloggerInfluencerCreator',
  Other = 'other',
}

export enum CompanyTypeV3 {
  Agency = 'agency',
  Brand = 'brand',
}

export type CompanyType = CompanyTypeV1 | CompanyTypeV2 | CompanyTypeV3;

export enum CompanyRoleV1 {
  OwnerOrLeader = 'ownerOrLeadership',
  MediaBuyerOrCreativeStrategist = 'mediaBuyerOrCreativeStrategist',
  VideoOrMotion = 'videoEditorOrMotionDesigner',
  DesignerOrCreative = 'designerCreative',
  BrandManagerOrProducer = 'brandManagerOrProducer',
  SocialMediaManager = 'socialMediaManager',
  Other = 'other',
}

export enum UserIndustryV1 {
  Apps = 'appsSoftware',
  ConsumerGoods = 'consumerGoods',
  Clothing = 'clothingApparel',
  Beauty = 'beautyPersonalCare',
  Food = 'foodBeverage',
  Health = 'healthcare',
  Electronics = 'electronicsTech',
  Kids = 'kids',
  Travel = 'travel',
  Fitness = 'fitness',
  Finance = 'finance',
  Education = 'education',
  Pets = 'pets',
  Sports = 'sportsOutdoor',
  Games = 'games',
  Services = 'services',
  Entertainment = 'entertainment',
  Other = 'other',
}

export const companyTypes: Array<CompanyTypeV3> = Object.values(CompanyTypeV3);
export const companyRoles: Array<CompanyRoleV1> = Object.values(CompanyRoleV1);
export const userIndustries: Array<UserIndustryV1> =
  Object.values(UserIndustryV1);

export const companySizes = [
  '1',
  '1-5',
  '5-25',
  '25-100',
  '100-250',
  '250-1000',
  '1000+',
];
